/*
 * File sizes are in exact bytes and needed for calculating the download progress
 */

module.exports = {
    de: {
        audio: {
            size: 175882752,
            url: 'https://loj.jesus.net/audio_german_lo_res.mp3',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=8pnPQC6xLnk'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    en: {
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=ubCNOcEjEFI'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    es: {
        audio: {
            size: 175929600,
            url: 'https://loj.jesus.net/audio_spanish_lo_res.mp3',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=vEXLFcfjBck'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    fr: {
        audio: {
            size: 176313216,
            url: 'https://loj.jesus.net/audio_french_lo_res.mp3',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=_OresYj3J2Q'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    hi: {
        audio: {
            size: 175878144,
            url: 'https://loj.jesus.net/audio_hindi_lo_res.mp3',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=RVywOZnZUU4'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    nl: {
        subtitle: {
            size: 173502,
            url: 'https://loj.jesus.net/subs-dutch.vtt',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=tzwlTYkYY3U'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    ta: {
        audio: {
            size: 176059008,
            url: 'https://loj.jesus.net/audio_tamil_lo_res.mp3',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=CQOh9UumOsw'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    te: {
        audio: {
            size: 175882368,
            url: 'https://loj.jesus.net/audio_telugu_lo_res.mp3',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=7gQir018rzU'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
    th: {
        audio: {
            size: 175881984,
            url: 'https://loj.jesus.net/audio_thai_lo_res.mp3',
        },
        video: {
            size: 763621087,
            url: 'https://loj.jesus.net/movie-full.mp4',
            youtubeUrl: 'https://www.youtube.com/watch?v=RCtlm4sB3Wg'
        },
        videoSmall: {
            size: 363335841,
            url: 'https://loj.jesus.net/movie_full_lo_res.mp4',
        },
    },
}