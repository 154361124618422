const DE = require('@/data/translations/de.json')
const EN = require('@/data/translations/en.json')
const ES = require('@/data/translations/es.json')
const FR = require('@/data/translations/fr.json')
const HI = require('@/data/translations/hi.json')
const NL = require('@/data/translations/nl.json')
const TA = require('@/data/translations/ta.json')
const TE = require('@/data/translations/te.json')
const TH = require('@/data/translations/th.json')

module.exports = {
    de: DE,
    en: EN,
    es: ES,
    fr: FR,
    hi: HI,
    nl: NL,
    ta: TA,
    te: TE,
    th: TH,
}