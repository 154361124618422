/*
 * This mixin is used for components that adjust on resizing:
 * Resizing is only triggered when the viewport width is changing (which also happens on orientation change)
 * The way this resizing is handled (horizontal only) is to work correctly on iOS and Android devices
 * The component that is loading this mixin needs to have its own resize function doing things
 */

export default {
    data() {
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        window.addEventListener('resize', this.checkWindowWidth)
    },
    destroyed() {
        window.removeEventListener('resize', this.checkWindowWidth)
    },
    methods: {
        checkWindowWidth() {
            if(this.windowWidth !== window.innerWidth) {
                this.windowWidth = window.innerWidth
                this.resize()
            }
        },
        resize() {
            // Do nothing here, add this function to the component that is loading this mixin
        }
    }
}
