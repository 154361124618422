// Basics
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// Data
import Languages from './../data/Languages'
import Settings from './../data/Settings'
import TimeCodes from './../data/TimeCodes'
import Translations from './../data/Translations'

export default new Vuex.Store({
    state: {
        about: false,
        audioState: {
            loaded: false,
            playing: false,
            paused: false,
        },
        baseUrl: window.location.origin,
        chapterSelection: false,
        controlsVisibility: true,
        currentTimeFrame: {
            chapter: 1,
            verse: 1,
        },
        debug: false,
        downloadSelection: false,
        incognito: false,
        installPrompt: false,
        isEmbedded: false,
        languages: Languages,
        languageSelection: false,
        locale: 'en',
        offlineAudioAvailable: false,
        offlineSubtitleAvailable: false,
        offlineVideoAvailable: false,
        online: navigator.onLine,
        options: false,
        settings: Settings,
        shareable: false,
        subtitleState: {
            loaded: false,
            playing: false,
            paused: false,
        },
        timeCodes: TimeCodes,
        timeFrames: {
            chapterFrom: 1,
            chapterTo: 21,
            verseFrom: 1,
            verseTo: 25,
        },
        translations: Translations,
        version: '1.2.7',
        videoState: {
            fullscreen: false,
            loaded: false,
            playing: false,
            paused: false,
            volume: 100,
        },
    },
    getters: {
        getAbout: state => {
            return state.about
        },
        getAudioState: state => {
            return state.audioState
        },
        getBaseUrl: state => {
            return state.baseUrl
        },
        getChaptersAndVersesByTime: state => {
            let timeCodes = {}
            Object.entries(state.timeCodes).forEach(([chapter, chapterValue]) => {
                Object.entries(chapterValue).forEach(([verse, value]) => {
                    let time = helperMethods.calculateToSeconds(value.hour, value.minutes, value.seconds)
                    timeCodes[time] = { chapter, verse }
                })
            })
            return timeCodes
        },
        getChapterSelection: state => {
            return state.chapterSelection
        },
        getControlsVisibility: state => {
            return state.controlsVisibility
        },
        getComponentTranslations: (state) => (component) => {
            return state.translations[state.locale].components[component]
        },
        getCurrentTimeFrame: state => {
            return state.currentTimeFrame
        },
        getDebug: state => {
            return state.debug
        },
        getDownloadSelection: state => {
            return state.downloadSelection
        },
        getDefaultTranslations: state => {
            return state.translations[state.locale].defaults
        },
        getIncognito: state => {
            return state.incognito
        },
        getInstallPrompt: state => {
            return state.installPrompt
        },
        getIsEmbedded: state => {
            return state.isEmbedded
        },
        getLanguages: state => {
            return state.languages
        },
        getLanguageByLocale: (state) => (locale = false) => {
            return locale ? state.languages[locale] : state.languages
        },
        getLanguageSelection: state => {
            return state.languageSelection
        },
        getLocale: state => {
            return state.locale
        },
        getOfflineAudioAvailable: state => {
            return state.offlineAudioAvailable
        },
        getOfflineSubtitleAvailable: state => {
            return state.offlineSubtitleAvailable
        },
        getOfflineVideoAvailable: state => {
            return state.offlineVideoAvailable
        },
        getOnline: state => {
            return state.online
        },
        getOptions: state => {
            return state.options
        },
        getShareable: state => {
            return state.shareable
        },
        getSetting: state => {
            return state.settings[state.locale]
        },
        getSubtitleState: state => {
            return state.subtitleState
        },
        getTimeCodes: state => {
            return state.timeCodes
        },
        getTimeFrames: state => {
            return state.timeFrames
        },
        getTimeCodeChapters: state => {
            let chapters = []
            for (let code in state.timeCodes) { chapters.push(code) }
            return chapters.sort(function(a, b) {
                return parseInt(a) < parseInt(b) ? -1 : 1
            });
        },
        getTimeCodeVerses: (state) => (chapter = 0) => {
            let verses = []
            for (let code in state.timeCodes[chapter]) { verses.push(code) }
            verses.sort(function (a, b) {
                return parseInt(a) < parseInt(b) ? -1 : 1
            });

            return verses
        },
        getTranslation: state => {
            return state.translations[state.locale]
        },
        getVersion: state => {
            return state.version
        },
        getVideoState: state => {
            return state.videoState
        },
        getVideoTimeFrames: state => {
            // Define start/end timeframes (or set defaults)
            let frames = { start: 0, end: 0 }
            if(state.timeFrames.chapterFrom) {
                let from = state.timeCodes[state.timeFrames.chapterFrom][(state.timeFrames.verseFrom > 0 ? state.timeFrames.verseFrom : 1)]
                frames.start = helperMethods.calculateToSeconds(from.hour, from.minutes, from.seconds)
            }
            if(state.timeFrames.chapterTo) {
                let chapterTo = state.timeCodes[state.timeFrames.chapterTo]
                let verseTo = {}
                // Select the next verse (if possible) in the same chapter, otherwise get the first verse of the next chapter
                if(chapterTo[state.timeFrames.verseTo + 1] === undefined) {
                    if(state.timeCodes[state.timeFrames.chapterTo + 1] === undefined) {
                        // This scenario only occurs in the last chapter and last verse (and create a custom end timeframe)
                        // Adding additional seconds to be able to play the final verse
                        verseTo = chapterTo[state.timeFrames.verseTo]
                        verseTo.seconds += 14
                    } else {
                        verseTo = state.timeCodes[state.timeFrames.chapterTo + 1][1]
                    }
                } else {
                    verseTo = chapterTo[state.timeFrames.verseTo + 1]
                }

                frames.end = helperMethods.calculateToSeconds(verseTo.hour, verseTo.minutes, verseTo.seconds)
            }
            return frames
        },
    },
    mutations: {
        RESET_ALL_OVERLAYS(state) {
            state.chapterSelection = false
            state.downloadSelection = false
            state.languageSelection = false
            state.options = false
            state.shareable = false
        },
        RESET_TIME_FRAMES(state) {
            state.timeFrames = {
                chapterFrom: 1,
                chapterTo: 21,
                verseFrom: 1,
                verseTo: 25,
            }
        },
        RESET_AUDIO_STATE(state) {
            state.audioState = {
                loaded: false,
                playing: false,
                paused: false,
            }
        },
        RESET_SUBTITLE_STATE(state) {
            state.subtitleState = {
                loaded: false,
                playing: false,
                paused: false,
            }
        },
        RESET_VIDEO_STATE(state) {
            state.videoState.fullscreen = false
            state.videoState.loaded = false
            state.videoState.playing = false
            state.videoState.paused = false
        },
        UPDATE_ABOUT(state, payload) {
            state.about = payload
        },
        UPDATE_AUDIO_LOADED(state, payload) {
            state.audioState.loaded = payload
        },
        UPDATE_AUDIO_PAUSED(state, payload) {
            state.audioState.paused = payload
        },
        UPDATE_AUDIO_PLAYING(state, payload) {
            state.audioState.playing = payload
        },
        UPDATE_CHAPTER_SELECTION(state, payload) {
            state.chapterSelection = payload
        },
        UPDATE_CONTROLS_VISIBILITY(state, payload) {
            state.controlsVisibility = payload
        },
        UPDATE_CURRENT_TIMEFRAME(state, payload) {
            state.currentTimeFrame = payload
        },
        UPDATE_DOWNLOAD_SELECTION(state, payload) {
            state.downloadSelection = payload
        },
        UPDATE_INCOGNITO(state, payload) {
            state.incognito = payload
        },
        UPDATE_INSTALL_PROMPT(state, payload) {
            state.installPrompt = payload
        },
        UPDATE_IS_EMBEDDED(state, payload) {
            state.isEmbedded = payload
        },
        UPDATE_LANGUAGE_SELECTION(state, payload) {
            state.languageSelection = payload
        },
        UPDATE_LOCALE(state, payload) {
            state.locale = payload
            localStorage.setItem('locale', payload)
        },
        UPDATE_OFFLINE_AUDIO_AVAILABLE(state, payload) {
            state.offlineAudioAvailable = payload
        },
        UPDATE_OFFLINE_SUBTITLE_AVAILABLE(state, payload) {
            state.offlineSubtitleAvailable = payload
        },
        UPDATE_OFFLINE_VIDEO_AVAILABLE(state, payload) {
            state.offlineVideoAvailable = payload
        },
        UPDATE_ONLINE(state, payload) {
            state.online = payload
        },
        UPDATE_OPTIONS(state, payload) {
            state.options = payload
        },
        UPDATE_SHAREABLE(state, payload) {
            state.shareable = payload
        },
        UPDATE_SUBTITLE_LOADED(state, payload) {
            state.subtitleState.loaded = payload
        },
        UPDATE_SUBTITLE_PAUSED(state, payload) {
            state.subtitleState.paused = payload
        },
        UPDATE_SUBTITLE_PLAYING(state, payload) {
            state.subtitleState.playing = payload
        },
        UPDATE_TIMEFRAME(state, payload) {
            if(payload.key !== undefined && payload.val !== undefined)
                state.timeFrames[payload.key] = parseInt(payload.val)
        },
        UPDATE_VIDEO_FULLSCREEN(state, payload) {
            state.videoState.fullscreen = payload
        },
        UPDATE_VIDEO_LOADED(state, payload) {
            state.videoState.loaded = payload
        },
        UPDATE_VIDEO_PAUSED(state, payload) {
            state.videoState.paused = payload
        },
        UPDATE_VIDEO_PLAYING(state, payload) {
            state.videoState.playing = payload
        },
        UPDATE_VIDEO_VOLUME(state, payload) {
            state.videoState.volume = payload
        },
    },
    actions: {
        resetAllOverlays({ commit }) {
            commit('RESET_ALL_OVERLAYS')
        },
        resetTimeFrames({ commit }) {
            commit('RESET_TIME_FRAMES')
        },
        resetAudioState({ commit }) {
            commit('RESET_AUDIO_STATE')
        },
        resetSubtitleState({ commit }) {
            commit('RESET_SUBTITLE_STATE')
        },
        resetVideoState({ commit }) {
            commit('RESET_VIDEO_STATE')
        },
        setAbout({ commit }, payload) {
            commit('UPDATE_ABOUT', payload)
        },
        setAudioLoaded({ commit }, payload) {
            commit('UPDATE_AUDIO_LOADED', payload)
        },
        setAudioPaused({ commit }, payload) {
            commit('UPDATE_AUDIO_PAUSED', payload)
        },
        setAudioPlaying({ commit }, payload) {
            commit('UPDATE_AUDIO_PLAYING', payload)
        },
        setChapterSelection({ commit }, payload) {
            commit('UPDATE_CHAPTER_SELECTION', payload)
        },
        setControlsVisibility({ commit }, payload) {
            commit('UPDATE_CONTROLS_VISIBILITY', payload)
        },
        setCurrentTimeFrame({ commit }, payload) {
            commit('UPDATE_CURRENT_TIMEFRAME', payload)
        },
        setDownloadSelection({ commit }, payload) {
            commit('UPDATE_DOWNLOAD_SELECTION', payload)
        },
        setIncognito({ commit }, payload) {
            commit('UPDATE_INCOGNITO', payload)
        },
        setInstallPrompt({ commit }, payload) {
            commit('UPDATE_INSTALL_PROMPT', payload)
        },
        setIsEmbedded({ commit }, payload) {
            commit('UPDATE_IS_EMBEDDED', payload)
        },
        setLanguageSelection({ commit }, payload) {
            commit('UPDATE_LANGUAGE_SELECTION', payload)
        },
        setLocale({ commit }, locale = 'en') {
            commit('UPDATE_LOCALE', locale)
        },
        setOfflineAudioAvailable({ commit }, payload) {
            commit('UPDATE_OFFLINE_AUDIO_AVAILABLE', payload)
        },
        setOfflineSubtitleAvailable({ commit }, payload) {
            commit('UPDATE_OFFLINE_SUBTITLE_AVAILABLE', payload)
        },
        setOfflineVideoAvailable({ commit }, payload) {
            commit('UPDATE_OFFLINE_VIDEO_AVAILABLE', payload)
        },
        setOnline({ commit }, payload) {
            commit('UPDATE_ONLINE', payload)
        },
        setOptions({ commit }, payload) {
            commit('UPDATE_OPTIONS', payload)
        },
        setShareable({ commit }, payload) {
            commit('UPDATE_SHAREABLE', payload)
        },
        setSubtitleLoaded({ commit }, payload) {
            commit('UPDATE_SUBTITLE_LOADED', payload)
        },
        setSubtitlePaused({ commit }, payload) {
            commit('UPDATE_SUBTITLE_PAUSED', payload)
        },
        setSubtitlePlaying({ commit }, payload) {
            commit('UPDATE_SUBTITLE_PLAYING', payload)
        },
        setTimeFrame({ commit }, payload) {
            commit('UPDATE_TIMEFRAME', payload)
        },
        setVideoFullscreen({ commit }, payload) {
            commit('UPDATE_VIDEO_FULLSCREEN', payload)
        },
        setVideoLoaded({ commit }, payload) {
            commit('UPDATE_VIDEO_LOADED', payload)
        },
        setVideoPaused({ commit }, payload) {
            commit('UPDATE_VIDEO_PAUSED', payload)
        },
        setVideoPlaying({ commit }, payload) {
            commit('UPDATE_VIDEO_PLAYING', payload)
        },
        setVideoVolume({ commit }, payload) {
            commit('UPDATE_VIDEO_VOLUME', parseInt(payload))
        },
    },
})

const helperMethods = {
    calculateToSeconds(hours = 0, minutes = 0, seconds = 0) {
        return (hours > 0 ? hours * 3600 : 0) + (minutes > 0 ? minutes * 60 : 0) + seconds
    }
}