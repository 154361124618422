/*
 * This mixin is used for translation texts in Vue.js components
 */

export default {
    props: {
        translations: {
            type: Object,
            default(obj) {
                return obj !== undefined ? obj : {}
            }
        }
    },
    methods: {
        trans(key = null) {
            if(key === null) return

            return this.translations[key] !== undefined ? this.translations[key] : key
        }
    }
}
