module.exports = {
    de: {
        code: "de",
        language: "Deutsch",
        translation: "German"
    },
    en: {
        code: "en",
        language: "English",
        translation: "English"
    },
    es: {
        code: "es",
        language: "Español",
        translation: "Spanish"
    },
    fr: {
        code: "fr",
        language: "Français",
        translation: "French"
    },
    // hi: {
    //     code: "hi",
    //     language: "हिंदी",
    //     translation: "Hindi"
    // },
    nl: {
        code: "nl",
        language: "Nederlands",
        translation: "Dutch"
    },
    ta: {
        code: "ta",
        language: "தமிழ்",
        translation: "Tamil"
    },
    // te: {
    //     code: "te",
    //     language: "టెలిగు",
    //     translation: "Telugu"
    // },
    th: {
        code: "th",
        language: "ภาษาไทย",
        translation: "Thai"
    },
}