module.exports = {
    1: {
        1: {
            hour: 0,
            minutes: 0,
            seconds: 51,
        },
        2: {
            hour: 0,
            minutes: 1,
            seconds: 5,
        },
        3: {
            hour: 0,
            minutes: 1,
            seconds: 11,
        },
        4: {
            hour: 0,
            minutes: 1,
            seconds: 19,
        },
        5: {
            hour: 0,
            minutes: 1,
            seconds: 25,
        },
        6: {
            hour: 0,
            minutes: 1,
            seconds: 55,
        },
        7: {
            hour: 0,
            minutes: 2,
            seconds: 0,
        },
        8: {
            hour: 0,
            minutes: 2,
            seconds: 9,
        },
        9: {
            hour: 0,
            minutes: 2,
            seconds: 15,
        },
        10: {
            hour: 0,
            minutes: 2,
            seconds: 35,
        },
        11: {
            hour: 0,
            minutes: 2,
            seconds: 53,
        },
        12: {
            hour: 0,
            minutes: 2,
            seconds: 59,
        },
        13: {
            hour: 0,
            minutes: 3,
            seconds: 7,
        },
        14: {
            hour: 0,
            minutes: 3,
            seconds: 23,
        },
        15: {
            hour: 0,
            minutes: 3,
            seconds: 43,
        },
        16: {
            hour: 0,
            minutes: 4,
            seconds: 3,
        },
        17: {
            hour: 0,
            minutes: 4,
            seconds: 10,
        },
        18: {
            hour: 0,
            minutes: 4,
            seconds: 20,
        },
        19: {
            hour: 0,
            minutes: 4,
            seconds: 33,
        },
        20: {
            hour: 0,
            minutes: 4,
            seconds: 41,
        },
        21: {
            hour: 0,
            minutes: 4,
            seconds: 51,
        },
        22: {
            hour: 0,
            minutes: 4,
            seconds: 55,
        },
        23: {
            hour: 0,
            minutes: 5,
            seconds: 3,
        },
        24: {
            hour: 0,
            minutes: 5,
            seconds: 21,
        },
        25: {
            hour: 0,
            minutes: 5,
            seconds: 24,
        },
        26: {
            hour: 0,
            minutes: 5,
            seconds: 34,
        },
        27: {
            hour: 0,
            minutes: 5,
            seconds: 45,
        },
        28: {
            hour: 0,
            minutes: 6,
            seconds: 4,
        },
        29: {
            hour: 0,
            minutes: 6,
            seconds: 16,
        },
        30: {
            hour: 0,
            minutes: 6,
            seconds: 45,
        },
        31: {
            hour: 0,
            minutes: 6,
            seconds: 57,
        },
        32: {
            hour: 0,
            minutes: 7,
            seconds: 9,
        },
        33: {
            hour: 0,
            minutes: 7,
            seconds: 18,
        },
        34: {
            hour: 0,
            minutes: 7,
            seconds: 35,
        },
        35: {
            hour: 0,
            minutes: 7,
            seconds: 48,
        },
        36: {
            hour: 0,
            minutes: 7,
            seconds: 54,
        },
        37: {
            hour: 0,
            minutes: 8,
            seconds: 2,
        },
        38: {
            hour: 0,
            minutes: 8,
            seconds: 22,
        },
        39: {
            hour: 0,
            minutes: 8,
            seconds: 42,
        },
        40: {
            hour: 0,
            minutes: 8,
            seconds: 57,
        },
        41: {
            hour: 0,
            minutes: 9,
            seconds: 4,
        },
        42: {
            hour: 0,
            minutes: 9,
            seconds: 26,
        },
        43: {
            hour: 0,
            minutes: 10,
            seconds: 8,
        },
        44: {
            hour: 0,
            minutes: 10,
            seconds: 33,
        },
        45: {
            hour: 0,
            minutes: 10,
            seconds: 46,
        },
        46: {
            hour: 0,
            minutes: 11,
            seconds: 0,
        },
        47: {
            hour: 0,
            minutes: 11,
            seconds: 22,
        },
        48: {
            hour: 0,
            minutes: 11,
            seconds: 39,
        },
        49: {
            hour: 0,
            minutes: 12,
            seconds: 22,
        },
        50: {
            hour: 0,
            minutes: 12,
            seconds: 32,
        },
        51: {
            hour: 0,
            minutes: 12,
            seconds: 40,
        },
    },
    2: {
        1: {
            hour: 0,
            minutes: 12,
            seconds: 52,
        },
        2: {
            hour: 0,
            minutes: 13,
            seconds: 22,
        },
        3: {
            hour: 0,
            minutes: 13,
            seconds: 41,
        },
        4: {
            hour: 0,
            minutes: 13,
            seconds: 47,
        },
        5: {
            hour: 0,
            minutes: 14,
            seconds: 0,
        },
        6: {
            hour: 0,
            minutes: 14,
            seconds: 9,
        },
        7: {
            hour: 0,
            minutes: 14,
            seconds: 21,
        },
        8: {
            hour: 0,
            minutes: 14,
            seconds: 39,
        },
        9: {
            hour: 0,
            minutes: 15,
            seconds: 11,
        },
        10: {
            hour: 0,
            minutes: 15,
            seconds: 27,
        },
        11: {
            hour: 0,
            minutes: 15,
            seconds: 57,
        },
        12: {
            hour: 0,
            minutes: 16,
            seconds: 7,
        },
        13: {
            hour: 0,
            minutes: 16,
            seconds: 20,
        },
        14: {
            hour: 0,
            minutes: 16,
            seconds: 56,
        },
        15: {
            hour: 0,
            minutes: 17,
            seconds: 17,
        },
        16: {
            hour: 0,
            minutes: 18,
            seconds: 18,
        },
        17: {
            hour: 0,
            minutes: 18,
            seconds: 35,
        },
        18: {
            hour: 0,
            minutes: 18,
            seconds: 45,
        },
        19: {
            hour: 0,
            minutes: 18,
            seconds: 53,
        },
        20: {
            hour: 0,
            minutes: 18,
            seconds: 57,
        },
        21: {
            hour: 0,
            minutes: 19,
            seconds: 5,
        },
        22: {
            hour: 0,
            minutes: 19,
            seconds: 18,
        },
        23: {
            hour: 0,
            minutes: 19,
            seconds: 37,
        },
        24: {
            hour: 0,
            minutes: 19,
            seconds: 46,
        },
        25: {
            hour: 0,
            minutes: 19,
            seconds: 51,
        },
    },
    3: {
        1: {
            hour: 0,
            minutes: 20,
            seconds: 0,
        },
        2: {
            hour: 0,
            minutes: 20,
            seconds: 6,
        },
        3: {
            hour: 0,
            minutes: 20,
            seconds: 23,
        },
        4: {
            hour: 0,
            minutes: 20,
            seconds: 31,
        },
        5: {
            hour: 0,
            minutes: 20,
            seconds: 40,
        },
        6: {
            hour: 0,
            minutes: 20,
            seconds: 48,
        },
        7: {
            hour: 0,
            minutes: 20,
            seconds: 55,
        },
        8: {
            hour: 0,
            minutes: 21,
            seconds: 6,
        },
        9: {
            hour: 0,
            minutes: 21,
            seconds: 19,
        },
        10: {
            hour: 0,
            minutes: 21,
            seconds: 23,
        },
        11: {
            hour: 0,
            minutes: 21,
            seconds: 32,
        },
        12: {
            hour: 0,
            minutes: 21,
            seconds: 42,
        },
        13: {
            hour: 0,
            minutes: 21,
            seconds: 58,
        },
        14: {
            hour: 0,
            minutes: 22,
            seconds: 8,
        },
        15: {
            hour: 0,
            minutes: 22,
            seconds: 16,
        },
        16: {
            hour: 0,
            minutes: 22,
            seconds: 20,
        },
        17: {
            hour: 0,
            minutes: 22,
            seconds: 30,
        },
        18: {
            hour: 0,
            minutes: 22,
            seconds: 37,
        },
        19: {
            hour: 0,
            minutes: 22,
            seconds: 49,
        },
        20: {
            hour: 0,
            minutes: 23,
            seconds: 1,
        },
        21: {
            hour: 0,
            minutes: 23,
            seconds: 11,
        },
        22: {
            hour: 0,
            minutes: 23,
            seconds: 20,
        },
        23: {
            hour: 0,
            minutes: 23,
            seconds: 29,
        },
        24: {
            hour: 0,
            minutes: 23,
            seconds: 40,
        },
        25: {
            hour: 0,
            minutes: 23,
            seconds: 44,
        },
        26: {
            hour: 0,
            minutes: 23,
            seconds: 50,
        },
        27: {
            hour: 0,
            minutes: 24,
            seconds: 0,
        },
        28: {
            hour: 0,
            minutes: 24,
            seconds: 6,
        },
        29: {
            hour: 0,
            minutes: 24,
            seconds: 13,
        },
        30: {
            hour: 0,
            minutes: 24,
            seconds: 24,
        },
        31: {
            hour: 0,
            minutes: 24,
            seconds: 30,
        },
        32: {
            hour: 0,
            minutes: 24,
            seconds: 42,
        },
        33: {
            hour: 0,
            minutes: 24,
            seconds: 47,
        },
        34: {
            hour: 0,
            minutes: 24,
            seconds: 52,
        },
        35: {
            hour: 0,
            minutes: 25,
            seconds: 2,
        },
        36: {
            hour: 0,
            minutes: 25,
            seconds: 6,
        },
    },
    4: {
        1: {
            hour: 0,
            minutes: 25,
            seconds: 20,
        },
        2: {
            hour: 0,
            minutes: 25,
            seconds: 29,
        },
        3: {
            hour: 0,
            minutes: 25,
            seconds: 35,
        },
        4: {
            hour: 0,
            minutes: 25,
            seconds: 40,
        },
        5: {
            hour: 0,
            minutes: 25,
            seconds: 47,
        },
        6: {
            hour: 0,
            minutes: 25,
            seconds: 57,
        },
        7: {
            hour: 0,
            minutes: 26,
            seconds: 19,
        },
        8: {
            hour: 0,
            minutes: 26,
            seconds: 34,
        },
        9: {
            hour: 0,
            minutes: 26,
            seconds: 37,
        },
        10: {
            hour: 0,
            minutes: 26,
            seconds: 47,
        },
        11: {
            hour: 0,
            minutes: 26,
            seconds: 55,
        },
        12: {
            hour: 0,
            minutes: 27,
            seconds: 1,
        },
        13: {
            hour: 0,
            minutes: 27,
            seconds: 11,
        },
        14: {
            hour: 0,
            minutes: 27,
            seconds: 14,
        },
        15: {
            hour: 0,
            minutes: 27,
            seconds: 26,
        },
        16: {
            hour: 0,
            minutes: 27,
            seconds: 35,
        },
        17: {
            hour: 0,
            minutes: 27,
            seconds: 39,
        },
        18: {
            hour: 0,
            minutes: 27,
            seconds: 44,
        },
        19: {
            hour: 0,
            minutes: 27,
            seconds: 53,
        },
        20: {
            hour: 0,
            minutes: 27,
            seconds: 56,
        },
        21: {
            hour: 0,
            minutes: 28,
            seconds: 4,
        },
        22: {
            hour: 0,
            minutes: 28,
            seconds: 13,
        },
        23: {
            hour: 0,
            minutes: 28,
            seconds: 22,
        },
        24: {
            hour: 0,
            minutes: 28,
            seconds: 37,
        },
        25: {
            hour: 0,
            minutes: 28,
            seconds: 47,
        },
        26: {
            hour: 0,
            minutes: 28,
            seconds: 55,
        },
        27: {
            hour: 0,
            minutes: 29,
            seconds: 1,
        },
        28: {
            hour: 0,
            minutes: 29,
            seconds: 16,
        },
        29: {
            hour: 0,
            minutes: 29,
            seconds: 25,
        },
        30: {
            hour: 0,
            minutes: 29,
            seconds: 34,
        },
        31: {
            hour: 0,
            minutes: 29,
            seconds: 38,
        },
        32: {
            hour: 0,
            minutes: 29,
            seconds: 42,
        },
        33: {
            hour: 0,
            minutes: 29,
            seconds: 46,
        },
        34: {
            hour: 0,
            minutes: 29,
            seconds: 51,
        },
        35: {
            hour: 0,
            minutes: 29,
            seconds: 56,
        },
        36: {
            hour: 0,
            minutes: 30,
            seconds: 8,
        },
        37: {
            hour: 0,
            minutes: 30,
            seconds: 17,
        },
        38: {
            hour: 0,
            minutes: 30,
            seconds: 22,
        },
        39: {
            hour: 0,
            minutes: 30,
            seconds: 32,
        },
        40: {
            hour: 0,
            minutes: 30,
            seconds: 41,
        },
        41: {
            hour: 0,
            minutes: 30,
            seconds: 51,
        },
        42: {
            hour: 0,
            minutes: 30,
            seconds: 54,
        },
        43: {
            hour: 0,
            minutes: 31,
            seconds: 9,
        },
        44: {
            hour: 0,
            minutes: 31,
            seconds: 14,
        },
        45: {
            hour: 0,
            minutes: 31,
            seconds: 23,
        },
        46: {
            hour: 0,
            minutes: 31,
            seconds: 37,
        },
        47: {
            hour: 0,
            minutes: 32,
            seconds: 13,
        },
        48: {
            hour: 0,
            minutes: 32,
            seconds: 28,
        },
        49: {
            hour: 0,
            minutes: 32,
            seconds: 35,
        },
        50: {
            hour: 0,
            minutes: 32,
            seconds: 48,
        },
        51: {
            hour: 0,
            minutes: 33,
            seconds: 11,
        },
        52: {
            hour: 0,
            minutes: 33,
            seconds: 36,
        },
        53: {
            hour: 0,
            minutes: 33,
            seconds: 44,
        },
        54: {
            hour: 0,
            minutes: 34,
            seconds: 6,
        },
    },
    5: {
        1: {
            hour: 0,
            minutes: 34,
            seconds: 14,
        },
        2: {
            hour: 0,
            minutes: 34,
            seconds: 21,
        },
        3: {
            hour: 0,
            minutes: 34,
            seconds: 36,
        },
        4: {
            hour: 0,
            minutes: 34,
            seconds: 36,
        },
        5: {
            hour: 0,
            minutes: 34,
            seconds: 52,
        },
        6: {
            hour: 0,
            minutes: 35,
            seconds: 1,
        },
        7: {
            hour: 0,
            minutes: 35,
            seconds: 20,
        },
        8: {
            hour: 0,
            minutes: 35,
            seconds: 35,
        },
        9: {
            hour: 0,
            minutes: 35,
            seconds: 46,
        },
        10: {
            hour: 0,
            minutes: 36,
            seconds: 58,
        },
        11: {
            hour: 0,
            minutes: 37,
            seconds: 9,
        },
        12: {
            hour: 0,
            minutes: 37,
            seconds: 16,
        },
        13: {
            hour: 0,
            minutes: 37,
            seconds: 18,
        },
        14: {
            hour: 0,
            minutes: 37,
            seconds: 29,
        },
        15: {
            hour: 0,
            minutes: 37,
            seconds: 47,
        },
        16: {
            hour: 0,
            minutes: 37,
            seconds: 59,
        },
        17: {
            hour: 0,
            minutes: 38,
            seconds: 8,
        },
        18: {
            hour: 0,
            minutes: 38,
            seconds: 18,
        },
        19: {
            hour: 0,
            minutes: 38,
            seconds: 33,
        },
        20: {
            hour: 0,
            minutes: 38,
            seconds: 48,
        },
        21: {
            hour: 0,
            minutes: 38,
            seconds: 58,
        },
        22: {
            hour: 0,
            minutes: 39,
            seconds: 5,
        },
        23: {
            hour: 0,
            minutes: 39,
            seconds: 11,
        },
        24: {
            hour: 0,
            minutes: 39,
            seconds: 21,
        },
        25: {
            hour: 0,
            minutes: 39,
            seconds: 32,
        },
        26: {
            hour: 0,
            minutes: 39,
            seconds: 45,
        },
        27: {
            hour: 0,
            minutes: 39,
            seconds: 52,
        },
        28: {
            hour: 0,
            minutes: 40,
            seconds: 0,
        },
        29: {
            hour: 0,
            minutes: 40,
            seconds: 6,
        },
        30: {
            hour: 0,
            minutes: 40,
            seconds: 21,
        },
        31: {
            hour: 0,
            minutes: 40,
            seconds: 33,
        },
        32: {
            hour: 0,
            minutes: 40,
            seconds: 38,
        },
        33: {
            hour: 0,
            minutes: 40,
            seconds: 45,
        },
        34: {
            hour: 0,
            minutes: 40,
            seconds: 51,
        },
        35: {
            hour: 0,
            minutes: 40,
            seconds: 56,
        },
        36: {
            hour: 0,
            minutes: 41,
            seconds: 3,
        },
        37: {
            hour: 0,
            minutes: 41,
            seconds: 18,
        },
        38: {
            hour: 0,
            minutes: 41,
            seconds: 25,
        },
        39: {
            hour: 0,
            minutes: 41,
            seconds: 30,
        },
        40: {
            hour: 0,
            minutes: 41,
            seconds: 37,
        },
        41: {
            hour: 0,
            minutes: 41,
            seconds: 41,
        },
        42: {
            hour: 0,
            minutes: 41,
            seconds: 45,
        },
        43: {
            hour: 0,
            minutes: 41,
            seconds: 52,
        },
        44: {
            hour: 0,
            minutes: 42,
            seconds: 4,
        },
        45: {
            hour: 0,
            minutes: 42,
            seconds: 13,
        },
        46: {
            hour: 0,
            minutes: 42,
            seconds: 22,
        },
        47: {
            hour: 0,
            minutes: 42,
            seconds: 28,
        },
    },
    6: {
        1: {
            hour: 0,
            minutes: 42,
            seconds: 46,
        },
        2: {
            hour: 0,
            minutes: 43,
            seconds: 8,
        },
        3: {
            hour: 0,
            minutes: 43,
            seconds: 45,
        },
        4: {
            hour: 0,
            minutes: 43,
            seconds: 58,
        },
        5: {
            hour: 0,
            minutes: 44,
            seconds: 1,
        },
        6: {
            hour: 0,
            minutes: 44,
            seconds: 23,
        },
        7: {
            hour: 0,
            minutes: 44,
            seconds: 30,
        },
        8: {
            hour: 0,
            minutes: 44,
            seconds: 40,
        },
        9: {
            hour: 0,
            minutes: 44,
            seconds: 45,
        },
        10: {
            hour: 0,
            minutes: 44,
            seconds: 59,
        },
        11: {
            hour: 0,
            minutes: 45,
            seconds: 18,
        },
        12: {
            hour: 0,
            minutes: 46,
            seconds: 33,
        },
        13: {
            hour: 0,
            minutes: 46,
            seconds: 45,
        },
        14: {
            hour: 0,
            minutes: 46,
            seconds: 55,
        },
        15: {
            hour: 0,
            minutes: 47,
            seconds: 6,
        },
        16: {
            hour: 0,
            minutes: 47,
            seconds: 22,
        },
        17: {
            hour: 0,
            minutes: 47,
            seconds: 25,
        },
        18: {
            hour: 0,
            minutes: 47,
            seconds: 39,
        },
        19: {
            hour: 0,
            minutes: 48,
            seconds: 3,
        },
        20: {
            hour: 0,
            minutes: 48,
            seconds: 34,
        },
        21: {
            hour: 0,
            minutes: 49,
            seconds: 2,
        },
        22: {
            hour: 0,
            minutes: 49,
            seconds: 21,
        },
        23: {
            hour: 0,
            minutes: 49,
            seconds: 34,
        },
        24: {
            hour: 0,
            minutes: 49,
            seconds: 43,
        },
        25: {
            hour: 0,
            minutes: 50,
            seconds: 2,
        },
        26: {
            hour: 0,
            minutes: 50,
            seconds: 10,
        },
        27: {
            hour: 0,
            minutes: 50,
            seconds: 21,
        },
        28: {
            hour: 0,
            minutes: 50,
            seconds: 38,
        },
        29: {
            hour: 0,
            minutes: 50,
            seconds: 43,
        },
        30: {
            hour: 0,
            minutes: 50,
            seconds: 47,
        },
        31: {
            hour: 0,
            minutes: 50,
            seconds: 55,
        },
        32: {
            hour: 0,
            minutes: 51,
            seconds: 3,
        },
        33: {
            hour: 0,
            minutes: 51,
            seconds: 12,
        },
        34: {
            hour: 0,
            minutes: 51,
            seconds: 17,
        },
        35: {
            hour: 0,
            minutes: 51,
            seconds: 21,
        },
        36: {
            hour: 0,
            minutes: 51,
            seconds: 32,
        },
        37: {
            hour: 0,
            minutes: 51,
            seconds: 39,
        },
        38: {
            hour: 0,
            minutes: 51,
            seconds: 46,
        },
        39: {
            hour: 0,
            minutes: 51,
            seconds: 53,
        },
        40: {
            hour: 0,
            minutes: 52,
            seconds: 4,
        },
        41: {
            hour: 0,
            minutes: 52,
            seconds: 16,
        },
        42: {
            hour: 0,
            minutes: 52,
            seconds: 23,
        },
        43: {
            hour: 0,
            minutes: 52,
            seconds: 55,
        },
        44: {
            hour: 0,
            minutes: 53,
            seconds: 0,
        },
        45: {
            hour: 0,
            minutes: 53,
            seconds: 18,
        },
        46: {
            hour: 0,
            minutes: 53,
            seconds: 37,
        },
        47: {
            hour: 0,
            minutes: 53,
            seconds: 45,
        },
        48: {
            hour: 0,
            minutes: 53,
            seconds: 54,
        },
        49: {
            hour: 0,
            minutes: 54,
            seconds: 1,
        },
        50: {
            hour: 0,
            minutes: 54,
            seconds: 5,
        },
        51: {
            hour: 0,
            minutes: 54,
            seconds: 12,
        },
        52: {
            hour: 0,
            minutes: 54,
            seconds: 28,
        },
        53: {
            hour: 0,
            minutes: 54,
            seconds: 35,
        },
        54: {
            hour: 0,
            minutes: 54,
            seconds: 44,
        },
        55: {
            hour: 0,
            minutes: 54,
            seconds: 52,
        },
        56: {
            hour: 0,
            minutes: 54,
            seconds: 57,
        },
        57: {
            hour: 0,
            minutes: 55,
            seconds: 6,
        },
        58: {
            hour: 0,
            minutes: 55,
            seconds: 18,
        },
        59: {
            hour: 0,
            minutes: 55,
            seconds: 36,
        },
        60: {
            hour: 0,
            minutes: 55,
            seconds: 41,
        },
        61: {
            hour: 0,
            minutes: 55,
            seconds: 50,
        },
        62: {
            hour: 0,
            minutes: 55,
            seconds: 57,
        },
        63: {
            hour: 0,
            minutes: 56,
            seconds: 4,
        },
        64: {
            hour: 0,
            minutes: 56,
            seconds: 14,
        },
        65: {
            hour: 0,
            minutes: 56,
            seconds: 29,
        },
        66: {
            hour: 0,
            minutes: 56,
            seconds: 37,
        },
        67: {
            hour: 0,
            minutes: 56,
            seconds: 48,
        },
        68: {
            hour: 0,
            minutes: 56,
            seconds: 56,
        },
        69: {
            hour: 0,
            minutes: 57,
            seconds: 2,
        },
        70: {
            hour: 0,
            minutes: 57,
            seconds: 18,
        },
        71: {
            hour: 0,
            minutes: 57,
            seconds: 28,
        },
    },
    7: {
        1: {
            hour: 0,
            minutes: 57,
            seconds: 39,
        },
        2: {
            hour: 0,
            minutes: 57,
            seconds: 51,
        },
        3: {
            hour: 0,
            minutes: 57,
            seconds: 54,
        },
        4: {
            hour: 0,
            minutes: 58,
            seconds: 3,
        },
        5: {
            hour: 0,
            minutes: 58,
            seconds: 11,
        },
        6: {
            hour: 0,
            minutes: 58,
            seconds: 15,
        },
        7: {
            hour: 0,
            minutes: 58,
            seconds: 20,
        },
        8: {
            hour: 0,
            minutes: 58,
            seconds: 26,
        },
        9: {
            hour: 0,
            minutes: 58,
            seconds: 35,
        },
        10: {
            hour: 0,
            minutes: 58,
            seconds: 40,
        },
        11: {
            hour: 0,
            minutes: 58,
            seconds: 50,
        },
        12: {
            hour: 0,
            minutes: 58,
            seconds: 56,
        },
        13: {
            hour: 0,
            minutes: 59,
            seconds: 5,
        },
        14: {
            hour: 0,
            minutes: 59,
            seconds: 13,
        },
        15: {
            hour: 0,
            minutes: 59,
            seconds: 20,
        },
        16: {
            hour: 0,
            minutes: 59,
            seconds: 31,
        },
        17: {
            hour: 0,
            minutes: 59,
            seconds: 35,
        },
        18: {
            hour: 0,
            minutes: 59,
            seconds: 41,
        },
        19: {
            hour: 0,
            minutes: 59,
            seconds: 52,
        },
        20: {
            hour: 1,
            minutes: 0,
            seconds: 3,
        },
        21: {
            hour: 1,
            minutes: 0,
            seconds: 6,
        },
        22: {
            hour: 1,
            minutes: 0,
            seconds: 10,
        },
        23: {
            hour: 1,
            minutes: 0,
            seconds: 20,
        },
        24: {
            hour: 1,
            minutes: 0,
            seconds: 31,
        },
        25: {
            hour: 1,
            minutes: 0,
            seconds: 38,
        },
        26: {
            hour: 1,
            minutes: 0,
            seconds: 44,
        },
        27: {
            hour: 1,
            minutes: 0,
            seconds: 54,
        },
        28: {
            hour: 1,
            minutes: 1,
            seconds: 4,
        },
        29: {
            hour: 1,
            minutes: 1,
            seconds: 22,
        },
        30: {
            hour: 1,
            minutes: 1,
            seconds: 30,
        },
        31: {
            hour: 1,
            minutes: 2,
            seconds: 5,
        },
        32: {
            hour: 1,
            minutes: 2,
            seconds: 16,
        },
        33: {
            hour: 1,
            minutes: 2,
            seconds: 32,
        },
        34: {
            hour: 1,
            minutes: 2,
            seconds: 40,
        },
        35: {
            hour: 1,
            minutes: 2,
            seconds: 47,
        },
        36: {
            hour: 1,
            minutes: 2,
            seconds: 58,
        },
        37: {
            hour: 1,
            minutes: 3,
            seconds: 13,
        },
        38: {
            hour: 1,
            minutes: 3,
            seconds: 21,
        },
        39: {
            hour: 1,
            minutes: 3,
            seconds: 29,
        },
        40: {
            hour: 1,
            minutes: 3,
            seconds: 41,
        },
        41: {
            hour: 1,
            minutes: 3,
            seconds: 49,
        },
        42: {
            hour: 1,
            minutes: 3,
            seconds: 52,
        },
        43: {
            hour: 1,
            minutes: 4,
            seconds: 2,
        },
        44: {
            hour: 1,
            minutes: 4,
            seconds: 6,
        },
        45: {
            hour: 1,
            minutes: 4,
            seconds: 12,
        },
        46: {
            hour: 1,
            minutes: 4,
            seconds: 20,
        },
        47: {
            hour: 1,
            minutes: 4,
            seconds: 23,
        },
        48: {
            hour: 1,
            minutes: 4,
            seconds: 26,
        },
        49: {
            hour: 1,
            minutes: 4,
            seconds: 32,
        },
        50: {
            hour: 1,
            minutes: 4,
            seconds: 43,
        },
        51: {
            hour: 1,
            minutes: 4,
            seconds: 48,
        },
        52: {
            hour: 1,
            minutes: 4,
            seconds: 56,
        },
    },
    8: {
        1: {
            hour: 1,
            minutes: 5,
            seconds: 13,
        },
        2: {
            hour: 1,
            minutes: 5,
            seconds: 25,
        },
        3: {
            hour: 1,
            minutes: 5,
            seconds: 35,
        },
        4: {
            hour: 1,
            minutes: 5,
            seconds: 44,
        },
        5: {
            hour: 1,
            minutes: 5,
            seconds: 54,
        },
        6: {
            hour: 1,
            minutes: 6,
            seconds: 8,
        },
        7: {
            hour: 1,
            minutes: 6,
            seconds: 24,
        },
        8: {
            hour: 1,
            minutes: 6,
            seconds: 57,
        },
        9: {
            hour: 1,
            minutes: 7,
            seconds: 5,
        },
        10: {
            hour: 1,
            minutes: 7,
            seconds: 40,
        },
        11: {
            hour: 1,
            minutes: 7,
            seconds: 56,
        },
        12: {
            hour: 1,
            minutes: 8,
            seconds: 29,
        },
        13: {
            hour: 1,
            minutes: 8,
            seconds: 40,
        },
        14: {
            hour: 1,
            minutes: 8,
            seconds: 44,
        },
        15: {
            hour: 1,
            minutes: 8,
            seconds: 59,
        },
        16: {
            hour: 1,
            minutes: 9,
            seconds: 4,
        },
        17: {
            hour: 1,
            minutes: 9,
            seconds: 17,
        },
        18: {
            hour: 1,
            minutes: 9,
            seconds: 25,
        },
        19: {
            hour: 1,
            minutes: 9,
            seconds: 31,
        },
        20: {
            hour: 1,
            minutes: 9,
            seconds: 41,
        },
        21: {
            hour: 1,
            minutes: 9,
            seconds: 52,
        },
        22: {
            hour: 1,
            minutes: 10,
            seconds: 0,
        },
        23: {
            hour: 1,
            minutes: 10,
            seconds: 7,
        },
        24: {
            hour: 1,
            minutes: 10,
            seconds: 16,
        },
        25: {
            hour: 1,
            minutes: 10,
            seconds: 27,
        },
        26: {
            hour: 1,
            minutes: 10,
            seconds: 36,
        },
        27: {
            hour: 1,
            minutes: 10,
            seconds: 51,
        },
        28: {
            hour: 1,
            minutes: 10,
            seconds: 56,
        },
        29: {
            hour: 1,
            minutes: 11,
            seconds: 10,
        },
        30: {
            hour: 1,
            minutes: 11,
            seconds: 18,
        },
        31: {
            hour: 1,
            minutes: 11,
            seconds: 25,
        },
        32: {
            hour: 1,
            minutes: 11,
            seconds: 29,
        },
        33: {
            hour: 1,
            minutes: 11,
            seconds: 33,
        },
        34: {
            hour: 1,
            minutes: 11,
            seconds: 41,
        },
        35: {
            hour: 1,
            minutes: 11,
            seconds: 46,
        },
        36: {
            hour: 1,
            minutes: 11,
            seconds: 50,
        },
        37: {
            hour: 1,
            minutes: 11,
            seconds: 54,
        },
        38: {
            hour: 1,
            minutes: 11,
            seconds: 59,
        },
        39: {
            hour: 1,
            minutes: 12,
            seconds: 4,
        },
        40: {
            hour: 1,
            minutes: 12,
            seconds: 12,
        },
        41: {
            hour: 1,
            minutes: 12,
            seconds: 20,
        },
        42: {
            hour: 1,
            minutes: 12,
            seconds: 31,
        },
        43: {
            hour: 1,
            minutes: 12,
            seconds: 44,
        },
        44: {
            hour: 1,
            minutes: 12,
            seconds: 51,
        },
        45: {
            hour: 1,
            minutes: 13,
            seconds: 13,
        },
        46: {
            hour: 1,
            minutes: 13,
            seconds: 19,
        },
        47: {
            hour: 1,
            minutes: 13,
            seconds: 28,
        },
        48: {
            hour: 1,
            minutes: 13,
            seconds: 37,
        },
        49: {
            hour: 1,
            minutes: 13,
            seconds: 42,
        },
        50: {
            hour: 1,
            minutes: 13,
            seconds: 48,
        },
        51: {
            hour: 1,
            minutes: 13,
            seconds: 55,
        },
        52: {
            hour: 1,
            minutes: 14,
            seconds: 2,
        },
        53: {
            hour: 1,
            minutes: 14,
            seconds: 11,
        },
        54: {
            hour: 1,
            minutes: 14,
            seconds: 22,
        },
        55: {
            hour: 1,
            minutes: 14,
            seconds: 32,
        },
        56: {
            hour: 1,
            minutes: 14,
            seconds: 42,
        },
        57: {
            hour: 1,
            minutes: 14,
            seconds: 52,
        },
        58: {
            hour: 1,
            minutes: 15,
            seconds: 2,
        },
        59: {
            hour: 1,
            minutes: 15,
            seconds: 24,
        },
    },
    9: {
        1: {
            hour: 1,
            minutes: 16,
            seconds: 20,
        },
        2: {
            hour: 1,
            minutes: 16,
            seconds: 28,
        },
        3: {
            hour: 1,
            minutes: 16,
            seconds: 40,
        },
        4: {
            hour: 1,
            minutes: 16,
            seconds: 49,
        },
        5: {
            hour: 1,
            minutes: 17,
            seconds: 4,
        },
        6: {
            hour: 1,
            minutes: 17,
            seconds: 20,
        },
        7: {
            hour: 1,
            minutes: 17,
            seconds: 54,
        },
        8: {
            hour: 1,
            minutes: 19,
            seconds: 10,
        },
        9: {
            hour: 1,
            minutes: 19,
            seconds: 18,
        },
        10: {
            hour: 1,
            minutes: 19,
            seconds: 24,
        },
        11: {
            hour: 1,
            minutes: 19,
            seconds: 26,
        },
        12: {
            hour: 1,
            minutes: 19,
            seconds: 37,
        },
        13: {
            hour: 1,
            minutes: 19,
            seconds: 41,
        },
        14: {
            hour: 1,
            minutes: 19,
            seconds: 46,
        },
        15: {
            hour: 1,
            minutes: 20,
            seconds: 6,
        },
        16: {
            hour: 1,
            minutes: 20,
            seconds: 16,
        },
        17: {
            hour: 1,
            minutes: 20,
            seconds: 29,
        },
        18: {
            hour: 1,
            minutes: 20,
            seconds: 37,
        },
        19: {
            hour: 1,
            minutes: 20,
            seconds: 44,
        },
        20: {
            hour: 1,
            minutes: 20,
            seconds: 54,
        },
        21: {
            hour: 1,
            minutes: 20,
            seconds: 59,
        },
        22: {
            hour: 1,
            minutes: 21,
            seconds: 11,
        },
        23: {
            hour: 1,
            minutes: 21,
            seconds: 21,
        },
        24: {
            hour: 1,
            minutes: 21,
            seconds: 26,
        },
        25: {
            hour: 1,
            minutes: 21,
            seconds: 42,
        },
        26: {
            hour: 1,
            minutes: 21,
            seconds: 58,
        },
        27: {
            hour: 1,
            minutes: 22,
            seconds: 3,
        },
        28: {
            hour: 1,
            minutes: 22,
            seconds: 10,
        },
        29: {
            hour: 1,
            minutes: 22,
            seconds: 18,
        },
        30: {
            hour: 1,
            minutes: 22,
            seconds: 25,
        },
        31: {
            hour: 1,
            minutes: 22,
            seconds: 31,
        },
        32: {
            hour: 1,
            minutes: 22,
            seconds: 37,
        },
        33: {
            hour: 1,
            minutes: 22,
            seconds: 42,
        },
        34: {
            hour: 1,
            minutes: 22,
            seconds: 46,
        },
        35: {
            hour: 1,
            minutes: 23,
            seconds: 20,
        },
        36: {
            hour: 1,
            minutes: 23,
            seconds: 25,
        },
        37: {
            hour: 1,
            minutes: 23,
            seconds: 30,
        },
        38: {
            hour: 1,
            minutes: 23,
            seconds: 37,
        },
        39: {
            hour: 1,
            minutes: 23,
            seconds: 44,
        },
        40: {
            hour: 1,
            minutes: 23,
            seconds: 54,
        },
        41: {
            hour: 1,
            minutes: 24,
            seconds: 6,
        },
    },
    10: {
        1: {
            hour: 1,
            minutes: 24,
            seconds: 20,
        },
        2: {
            hour: 1,
            minutes: 24,
            seconds: 29,
        },
        3: {
            hour: 1,
            minutes: 24,
            seconds: 34,
        },
        4: {
            hour: 1,
            minutes: 24,
            seconds: 41,
        },
        5: {
            hour: 1,
            minutes: 24,
            seconds: 47,
        },
        6: {
            hour: 1,
            minutes: 24,
            seconds: 54,
        },
        7: {
            hour: 1,
            minutes: 24,
            seconds: 59,
        },
        8: {
            hour: 1,
            minutes: 25,
            seconds: 5,
        },
        9: {
            hour: 1,
            minutes: 25,
            seconds: 9,
        },
        10: {
            hour: 1,
            minutes: 25,
            seconds: 17,
        },
        11: {
            hour: 1,
            minutes: 25,
            seconds: 30,
        },
        12: {
            hour: 1,
            minutes: 25,
            seconds: 35,
        },
        13: {
            hour: 1,
            minutes: 25,
            seconds: 48,
        },
        14: {
            hour: 1,
            minutes: 25,
            seconds: 55,
        },
        15: {
            hour: 1,
            minutes: 25,
            seconds: 56,
        },
        16: {
            hour: 1,
            minutes: 26,
            seconds: 11,
        },
        17: {
            hour: 1,
            minutes: 26,
            seconds: 24,
        },
        18: {
            hour: 1,
            minutes: 26,
            seconds: 31,
        },
        19: {
            hour: 1,
            minutes: 26,
            seconds: 45,
        },
        20: {
            hour: 1,
            minutes: 26,
            seconds: 49,
        },
        21: {
            hour: 1,
            minutes: 26,
            seconds: 53,
        },
        22: {
            hour: 1,
            minutes: 27,
            seconds: 5,
        },
        23: {
            hour: 1,
            minutes: 27,
            seconds: 18,
        },
        24: {
            hour: 1,
            minutes: 27,
            seconds: 21,
        },
        25: {
            hour: 1,
            minutes: 27,
            seconds: 30,
        },
        26: {
            hour: 1,
            minutes: 27,
            seconds: 40,
        },
        27: {
            hour: 1,
            minutes: 27,
            seconds: 48,
        },
        28: {
            hour: 1,
            minutes: 27,
            seconds: 54,
        },
        29: {
            hour: 1,
            minutes: 28,
            seconds: 0,
        },
        30: {
            hour: 1,
            minutes: 28,
            seconds: 6,
        },
        31: {
            hour: 1,
            minutes: 28,
            seconds: 10,
        },
        32: {
            hour: 1,
            minutes: 28,
            seconds: 30,
        },
        33: {
            hour: 1,
            minutes: 28,
            seconds: 39,
        },
        34: {
            hour: 1,
            minutes: 28,
            seconds: 56,
        },
        35: {
            hour: 1,
            minutes: 29,
            seconds: 2,
        },
        36: {
            hour: 1,
            minutes: 29,
            seconds: 12,
        },
        37: {
            hour: 1,
            minutes: 29,
            seconds: 23,
        },
        38: {
            hour: 1,
            minutes: 29,
            seconds: 27,
        },
        39: {
            hour: 1,
            minutes: 29,
            seconds: 47,
        },
        40: {
            hour: 1,
            minutes: 30,
            seconds: 8,
        },
        41: {
            hour: 1,
            minutes: 30,
            seconds: 19,
        },
        42: {
            hour: 1,
            minutes: 30,
            seconds: 29,
        },
    },
    11: {
        1: {
            hour: 1,
            minutes: 30,
            seconds: 34,
        },
        2: {
            hour: 1,
            minutes: 30,
            seconds: 51,
        },
        3: {
            hour: 1,
            minutes: 31,
            seconds: 8,
        },
        4: {
            hour: 1,
            minutes: 31,
            seconds: 17,
        },
        5: {
            hour: 1,
            minutes: 31,
            seconds: 34,
        },
        6: {
            hour: 1,
            minutes: 31,
            seconds: 39,
        },
        7: {
            hour: 1,
            minutes: 31,
            seconds: 45,
        },
        8: {
            hour: 1,
            minutes: 31,
            seconds: 49,
        },
        9: {
            hour: 1,
            minutes: 31,
            seconds: 55,
        },
        10: {
            hour: 1,
            minutes: 32,
            seconds: 3,
        },
        11: {
            hour: 1,
            minutes: 32,
            seconds: 7,
        },
        12: {
            hour: 1,
            minutes: 32,
            seconds: 18,
        },
        13: {
            hour: 1,
            minutes: 32,
            seconds: 24,
        },
        14: {
            hour: 1,
            minutes: 32,
            seconds: 29,
        },
        15: {
            hour: 1,
            minutes: 32,
            seconds: 33,
        },
        16: {
            hour: 1,
            minutes: 32,
            seconds: 48,
        },
        17: {
            hour: 1,
            minutes: 32,
            seconds: 57,
        },
        18: {
            hour: 1,
            minutes: 33,
            seconds: 4,
        },
        19: {
            hour: 1,
            minutes: 33,
            seconds: 8,
        },
        20: {
            hour: 1,
            minutes: 33,
            seconds: 13,
        },
        21: {
            hour: 1,
            minutes: 33,
            seconds: 21,
        },
        22: {
            hour: 1,
            minutes: 33,
            seconds: 28,
        },
        23: {
            hour: 1,
            minutes: 33,
            seconds: 34,
        },
        24: {
            hour: 1,
            minutes: 33,
            seconds: 37,
        },
        25: {
            hour: 1,
            minutes: 33,
            seconds: 41,
        },
        26: {
            hour: 1,
            minutes: 33,
            seconds: 51,
        },
        27: {
            hour: 1,
            minutes: 34,
            seconds: 1,
        },
        28: {
            hour: 1,
            minutes: 34,
            seconds: 16,
        },
        29: {
            hour: 1,
            minutes: 34,
            seconds: 31,
        },
        30: {
            hour: 1,
            minutes: 34,
            seconds: 36,
        },
        31: {
            hour: 1,
            minutes: 34,
            seconds: 42,
        },
        32: {
            hour: 1,
            minutes: 34,
            seconds: 52,
        },
        33: {
            hour: 1,
            minutes: 35,
            seconds: 28,
        },
        34: {
            hour: 1,
            minutes: 35,
            seconds: 44,
        },
        35: {
            hour: 1,
            minutes: 36,
            seconds: 8,
        },
        36: {
            hour: 1,
            minutes: 36,
            seconds: 13,
        },
        37: {
            hour: 1,
            minutes: 36,
            seconds: 16,
        },
        38: {
            hour: 1,
            minutes: 36,
            seconds: 26,
        },
        39: {
            hour: 1,
            minutes: 36,
            seconds: 36,
        },
        40: {
            hour: 1,
            minutes: 36,
            seconds: 43,
        },
        41: {
            hour: 1,
            minutes: 36,
            seconds: 51,
        },
        42: {
            hour: 1,
            minutes: 37,
            seconds: 24,
        },
        43: {
            hour: 1,
            minutes: 37,
            seconds: 42,
        },
        44: {
            hour: 1,
            minutes: 37,
            seconds: 58,
        },
        45: {
            hour: 1,
            minutes: 38,
            seconds: 46,
        },
        46: {
            hour: 1,
            minutes: 38,
            seconds: 53,
        },
        47: {
            hour: 1,
            minutes: 39,
            seconds: 1,
        },
        48: {
            hour: 1,
            minutes: 39,
            seconds: 9,
        },
        49: {
            hour: 1,
            minutes: 39,
            seconds: 21,
        },
        50: {
            hour: 1,
            minutes: 39,
            seconds: 29,
        },
        51: {
            hour: 1,
            minutes: 39,
            seconds: 45,
        },
        52: {
            hour: 1,
            minutes: 39,
            seconds: 54,
        },
        53: {
            hour: 1,
            minutes: 40,
            seconds: 4,
        },
        54: {
            hour: 1,
            minutes: 40,
            seconds: 10,
        },
        55: {
            hour: 1,
            minutes: 40,
            seconds: 20,
        },
        56: {
            hour: 1,
            minutes: 40,
            seconds: 31,
        },
        57: {
            hour: 1,
            minutes: 40,
            seconds: 41,
        },
    },
    12: {
        1: {
            hour: 1,
            minutes: 40,
            seconds: 52,
        },
        2: {
            hour: 1,
            minutes: 41,
            seconds: 10,
        },
        3: {
            hour: 1,
            minutes: 41,
            seconds: 25,
        },
        4: {
            hour: 1,
            minutes: 41,
            seconds: 57,
        },
        5: {
            hour: 1,
            minutes: 42,
            seconds: 4,
        },
        6: {
            hour: 1,
            minutes: 42,
            seconds: 17,
        },
        7: {
            hour: 1,
            minutes: 42,
            seconds: 27,
        },
        8: {
            hour: 1,
            minutes: 42,
            seconds: 34,
        },
        9: {
            hour: 1,
            minutes: 42,
            seconds: 52,
        },
        10: {
            hour: 1,
            minutes: 43,
            seconds: 5,
        },
        11: {
            hour: 1,
            minutes: 43,
            seconds: 10,
        },
        12: {
            hour: 1,
            minutes: 43,
            seconds: 18,
        },
        13: {
            hour: 1,
            minutes: 43,
            seconds: 24,
        },
        14: {
            hour: 1,
            minutes: 43,
            seconds: 38,
        },
        15: {
            hour: 1,
            minutes: 43,
            seconds: 43,
        },
        16: {
            hour: 1,
            minutes: 44,
            seconds: 1,
        },
        17: {
            hour: 1,
            minutes: 44,
            seconds: 22,
        },
        18: {
            hour: 1,
            minutes: 44,
            seconds: 31,
        },
        19: {
            hour: 1,
            minutes: 44,
            seconds: 38,
        },
        20: {
            hour: 1,
            minutes: 44,
            seconds: 55,
        },
        21: {
            hour: 1,
            minutes: 45,
            seconds: 3,
        },
        22: {
            hour: 1,
            minutes: 45,
            seconds: 12,
        },
        23: {
            hour: 1,
            minutes: 45,
            seconds: 30,
        },
        24: {
            hour: 1,
            minutes: 45,
            seconds: 36,
        },
        25: {
            hour: 1,
            minutes: 46,
            seconds: 3,
        },
        26: {
            hour: 1,
            minutes: 46,
            seconds: 14,
        },
        27: {
            hour: 1,
            minutes: 46,
            seconds: 37,
        },
        28: {
            hour: 1,
            minutes: 47,
            seconds: 10,
        },
        29: {
            hour: 1,
            minutes: 47,
            seconds: 26,
        },
        30: {
            hour: 1,
            minutes: 47,
            seconds: 36,
        },
        31: {
            hour: 1,
            minutes: 47,
            seconds: 40,
        },
        32: {
            hour: 1,
            minutes: 47,
            seconds: 46,
        },
        33: {
            hour: 1,
            minutes: 47,
            seconds: 51,
        },
        34: {
            hour: 1,
            minutes: 47,
            seconds: 56,
        },
        35: {
            hour: 1,
            minutes: 48,
            seconds: 5,
        },
        36: {
            hour: 1,
            minutes: 48,
            seconds: 33,
        },
        37: {
            hour: 1,
            minutes: 48,
            seconds: 51,
        },
        38: {
            hour: 1,
            minutes: 48,
            seconds: 58,
        },
        39: {
            hour: 1,
            minutes: 49,
            seconds: 11,
        },
        40: {
            hour: 1,
            minutes: 49,
            seconds: 15,
        },
        41: {
            hour: 1,
            minutes: 49,
            seconds: 29,
        },
        42: {
            hour: 1,
            minutes: 49,
            seconds: 38,
        },
        43: {
            hour: 1,
            minutes: 49,
            seconds: 51,
        },
        44: {
            hour: 1,
            minutes: 49,
            seconds: 55,
        },
        45: {
            hour: 1,
            minutes: 50,
            seconds: 4,
        },
        46: {
            hour: 1,
            minutes: 50,
            seconds: 9,
        },
        47: {
            hour: 1,
            minutes: 50,
            seconds: 16,
        },
        48: {
            hour: 1,
            minutes: 50,
            seconds: 26,
        },
        49: {
            hour: 1,
            minutes: 50,
            seconds: 37,
        },
        50: {
            hour: 1,
            minutes: 50,
            seconds: 47,
        },
    },
    13: {
        1: {
            hour: 1,
            minutes: 51,
            seconds: 13,
        },
        2: {
            hour: 1,
            minutes: 51,
            seconds: 41,
        },
        3: {
            hour: 1,
            minutes: 51,
            seconds: 54,
        },
        4: {
            hour: 1,
            minutes: 52,
            seconds: 22,
        },
        5: {
            hour: 1,
            minutes: 52,
            seconds: 43,
        },
        6: {
            hour: 1,
            minutes: 53,
            seconds: 6,
        },
        7: {
            hour: 1,
            minutes: 53,
            seconds: 15,
        },
        8: {
            hour: 1,
            minutes: 53,
            seconds: 20,
        },
        9: {
            hour: 1,
            minutes: 53,
            seconds: 36,
        },
        10: {
            hour: 1,
            minutes: 53,
            seconds: 48,
        },
        11: {
            hour: 1,
            minutes: 54,
            seconds: 8,
        },
        12: {
            hour: 1,
            minutes: 54,
            seconds: 29,
        },
        13: {
            hour: 1,
            minutes: 54,
            seconds: 40,
        },
        14: {
            hour: 1,
            minutes: 54,
            seconds: 48,
        },
        15: {
            hour: 1,
            minutes: 54,
            seconds: 57,
        },
        16: {
            hour: 1,
            minutes: 55,
            seconds: 5,
        },
        17: {
            hour: 1,
            minutes: 55,
            seconds: 12,
        },
        18: {
            hour: 1,
            minutes: 55,
            seconds: 24,
        },
        19: {
            hour: 1,
            minutes: 55,
            seconds: 38,
        },
        20: {
            hour: 1,
            minutes: 55,
            seconds: 47,
        },
        21: {
            hour: 1,
            minutes: 56,
            seconds: 18,
        },
        22: {
            hour: 1,
            minutes: 56,
            seconds: 44,
        },
        23: {
            hour: 1,
            minutes: 56,
            seconds: 50,
        },
        24: {
            hour: 1,
            minutes: 56,
            seconds: 56,
        },
        25: {
            hour: 1,
            minutes: 57,
            seconds: 3,
        },
        26: {
            hour: 1,
            minutes: 57,
            seconds: 13,
        },
        27: {
            hour: 1,
            minutes: 57,
            seconds: 33,
        },
        28: {
            hour: 1,
            minutes: 57,
            seconds: 46,
        },
        29: {
            hour: 1,
            minutes: 57,
            seconds: 52,
        },
        30: {
            hour: 1,
            minutes: 58,
            seconds: 6,
        },
        31: {
            hour: 1,
            minutes: 58,
            seconds: 22,
        },
        32: {
            hour: 1,
            minutes: 58,
            seconds: 33,
        },
        33: {
            hour: 1,
            minutes: 58,
            seconds: 56,
        },
        34: {
            hour: 1,
            minutes: 59,
            seconds: 22,
        },
        35: {
            hour: 1,
            minutes: 59,
            seconds: 34,
        },
        36: {
            hour: 1,
            minutes: 59,
            seconds: 41,
        },
        37: {
            hour: 1,
            minutes: 59,
            seconds: 47,
        },
        38: {
            hour: 1,
            minutes: 59,
            seconds: 55,
        },
    },
    14: {
        1: {
            hour: 2,
            minutes: 0,
            seconds: 8,
        },
        2: {
            hour: 2,
            minutes: 0,
            seconds: 18,
        },
        3: {
            hour: 2,
            minutes: 0,
            seconds: 30,
        },
        4: {
            hour: 2,
            minutes: 0,
            seconds: 38,
        },
        5: {
            hour: 2,
            minutes: 0,
            seconds: 43,
        },
        6: {
            hour: 2,
            minutes: 0,
            seconds: 49,
        },
        7: {
            hour: 2,
            minutes: 1,
            seconds: 1,
        },
        8: {
            hour: 2,
            minutes: 1,
            seconds: 11,
        },
        9: {
            hour: 2,
            minutes: 1,
            seconds: 21,
        },
        10: {
            hour: 2,
            minutes: 1,
            seconds: 33,
        },
        11: {
            hour: 2,
            minutes: 1,
            seconds: 48,
        },
        12: {
            hour: 2,
            minutes: 1,
            seconds: 56,
        },
        13: {
            hour: 2,
            minutes: 2,
            seconds: 7,
        },
        14: {
            hour: 2,
            minutes: 2,
            seconds: 16,
        },
        15: {
            hour: 2,
            minutes: 2,
            seconds: 27,
        },
        16: {
            hour: 2,
            minutes: 2,
            seconds: 30,
        },
        17: {
            hour: 2,
            minutes: 2,
            seconds: 35,
        },
        18: {
            hour: 2,
            minutes: 2,
            seconds: 47,
        },
        19: {
            hour: 2,
            minutes: 2,
            seconds: 52,
        },
        20: {
            hour: 2,
            minutes: 3,
            seconds: 1,
        },
        21: {
            hour: 2,
            minutes: 3,
            seconds: 9,
        },
        22: {
            hour: 2,
            minutes: 3,
            seconds: 20,
        },
        23: {
            hour: 2,
            minutes: 3,
            seconds: 29,
        },
        24: {
            hour: 2,
            minutes: 3,
            seconds: 35,
        },
        25: {
            hour: 2,
            minutes: 3,
            seconds: 44,
        },
        26: {
            hour: 2,
            minutes: 3,
            seconds: 46,
        },
        27: {
            hour: 2,
            minutes: 3,
            seconds: 56,
        },
        28: {
            hour: 2,
            minutes: 4,
            seconds: 11,
        },
        29: {
            hour: 2,
            minutes: 4,
            seconds: 26,
        },
        30: {
            hour: 2,
            minutes: 4,
            seconds: 34,
        },
        31: {
            hour: 2,
            minutes: 4,
            seconds: 41,
        },
    },
    15: {
        1: {
            hour: 2,
            minutes: 4,
            seconds: 53,
        },
        2: {
            hour: 2,
            minutes: 4,
            seconds: 57,
        },
        3: {
            hour: 2,
            minutes: 5,
            seconds: 7,
        },
        4: {
            hour: 2,
            minutes: 5,
            seconds: 11,
        },
        5: {
            hour: 2,
            minutes: 5,
            seconds: 36,
        },
        6: {
            hour: 2,
            minutes: 5,
            seconds: 49,
        },
        7: {
            hour: 2,
            minutes: 6,
            seconds: 2,
        },
        8: {
            hour: 2,
            minutes: 6,
            seconds: 11,
        },
        9: {
            hour: 2,
            minutes: 6,
            seconds: 19,
        },
        10: {
            hour: 2,
            minutes: 6,
            seconds: 25,
        },
        11: {
            hour: 2,
            minutes: 6,
            seconds: 34,
        },
        12: {
            hour: 2,
            minutes: 6,
            seconds: 41,
        },
        13: {
            hour: 2,
            minutes: 6,
            seconds: 45,
        },
        14: {
            hour: 2,
            minutes: 6,
            seconds: 49,
        },
        15: {
            hour: 2,
            minutes: 6,
            seconds: 53,
        },
        16: {
            hour: 2,
            minutes: 7,
            seconds: 5,
        },
        17: {
            hour: 2,
            minutes: 7,
            seconds: 24,
        },
        18: {
            hour: 2,
            minutes: 7,
            seconds: 37,
        },
        19: {
            hour: 2,
            minutes: 7,
            seconds: 45,
        },
        20: {
            hour: 2,
            minutes: 8,
            seconds: 0,
        },
        21: {
            hour: 2,
            minutes: 8,
            seconds: 16,
        },
        22: {
            hour: 2,
            minutes: 8,
            seconds: 22,
        },
        23: {
            hour: 2,
            minutes: 8,
            seconds: 32,
        },
        24: {
            hour: 2,
            minutes: 8,
            seconds: 36,
        },
        25: {
            hour: 2,
            minutes: 8,
            seconds: 50,
        },
        26: {
            hour: 2,
            minutes: 9,
            seconds: 1,
        },
        27: {
            hour: 2,
            minutes: 9,
            seconds: 10,
        },
    },
    16: {
        1: {
            hour: 2,
            minutes: 9,
            seconds: 16,
        },
        2: {
            hour: 2,
            minutes: 9,
            seconds: 20,
        },
        3: {
            hour: 2,
            minutes: 9,
            seconds: 31,
        },
        4: {
            hour: 2,
            minutes: 9,
            seconds: 39,
        },
        5: {
            hour: 2,
            minutes: 9,
            seconds: 54,
        },
        6: {
            hour: 2,
            minutes: 9,
            seconds: 59,
        },
        7: {
            hour: 2,
            minutes: 10,
            seconds: 6,
        },
        8: {
            hour: 2,
            minutes: 10,
            seconds: 22,
        },
        9: {
            hour: 2,
            minutes: 10,
            seconds: 34,
        },
        10: {
            hour: 2,
            minutes: 10,
            seconds: 39,
        },
        11: {
            hour: 2,
            minutes: 10,
            seconds: 48,
        },
        12: {
            hour: 2,
            minutes: 10,
            seconds: 57,
        },
        13: {
            hour: 2,
            minutes: 11,
            seconds: 3,
        },
        14: {
            hour: 2,
            minutes: 11,
            seconds: 15,
        },
        15: {
            hour: 2,
            minutes: 11,
            seconds: 20,
        },
        16: {
            hour: 2,
            minutes: 11,
            seconds: 27,
        },
        17: {
            hour: 2,
            minutes: 11,
            seconds: 37,
        },
        18: {
            hour: 2,
            minutes: 11,
            seconds: 51,
        },
        19: {
            hour: 2,
            minutes: 11,
            seconds: 58,
        },
        20: {
            hour: 2,
            minutes: 12,
            seconds: 12,
        },
        21: {
            hour: 2,
            minutes: 12,
            seconds: 22,
        },
        22: {
            hour: 2,
            minutes: 12,
            seconds: 35,
        },
        23: {
            hour: 2,
            minutes: 12,
            seconds: 47,
        },
        24: {
            hour: 2,
            minutes: 12,
            seconds: 59,
        },
        25: {
            hour: 2,
            minutes: 13,
            seconds: 15,
        },
        26: {
            hour: 2,
            minutes: 13,
            seconds: 23,
        },
        27: {
            hour: 2,
            minutes: 13,
            seconds: 29,
        },
        28: {
            hour: 2,
            minutes: 13,
            seconds: 39,
        },
        29: {
            hour: 2,
            minutes: 13,
            seconds: 48,
        },
        30: {
            hour: 2,
            minutes: 13,
            seconds: 53,
        },
        31: {
            hour: 2,
            minutes: 14,
            seconds: 7,
        },
        32: {
            hour: 2,
            minutes: 14,
            seconds: 9,
        },
        33: {
            hour: 2,
            minutes: 14,
            seconds: 23,
        },
    },
    17: {
        1: {
            hour: 2,
            minutes: 14,
            seconds: 35,
        },
        2: {
            hour: 2,
            minutes: 14,
            seconds: 58,
        },
        3: {
            hour: 2,
            minutes: 15,
            seconds: 5,
        },
        4: {
            hour: 2,
            minutes: 15,
            seconds: 12,
        },
        5: {
            hour: 2,
            minutes: 15,
            seconds: 18,
        },
        6: {
            hour: 2,
            minutes: 15,
            seconds: 26,
        },
        7: {
            hour: 2,
            minutes: 15,
            seconds: 36,
        },
        8: {
            hour: 2,
            minutes: 15,
            seconds: 39,
        },
        9: {
            hour: 2,
            minutes: 15,
            seconds: 47,
        },
        10: {
            hour: 2,
            minutes: 16,
            seconds: 1,
        },
        11: {
            hour: 2,
            minutes: 16,
            seconds: 12,
        },
        12: {
            hour: 2,
            minutes: 16,
            seconds: 32,
        },
        13: {
            hour: 2,
            minutes: 16,
            seconds: 46,
        },
        14: {
            hour: 2,
            minutes: 16,
            seconds: 56,
        },
        15: {
            hour: 2,
            minutes: 17,
            seconds: 3,
        },
        16: {
            hour: 2,
            minutes: 17,
            seconds: 10,
        },
        17: {
            hour: 2,
            minutes: 17,
            seconds: 15,
        },
        18: {
            hour: 2,
            minutes: 17,
            seconds: 23,
        },
        19: {
            hour: 2,
            minutes: 17,
            seconds: 27,
        },
        20: {
            hour: 2,
            minutes: 17,
            seconds: 35,
        },
        21: {
            hour: 2,
            minutes: 17,
            seconds: 40,
        },
        22: {
            hour: 2,
            minutes: 17,
            seconds: 55,
        },
        23: {
            hour: 2,
            minutes: 18,
            seconds: 1,
        },
        24: {
            hour: 2,
            minutes: 18,
            seconds: 14,
        },
        25: {
            hour: 2,
            minutes: 18,
            seconds: 30,
        },
        26: {
            hour: 2,
            minutes: 18,
            seconds: 40,
        },
    },
    18: {
        1: {
            hour: 2,
            minutes: 18,
            seconds: 52,
        },
        2: {
            hour: 2,
            minutes: 19,
            seconds: 22,
        },
        3: {
            hour: 2,
            minutes: 19,
            seconds: 32,
        },
        4: {
            hour: 2,
            minutes: 19,
            seconds: 58,
        },
        5: {
            hour: 2,
            minutes: 20,
            seconds: 5,
        },
        6: {
            hour: 2,
            minutes: 20,
            seconds: 15,
        },
        7: {
            hour: 2,
            minutes: 20,
            seconds: 31,
        },
        8: {
            hour: 2,
            minutes: 20,
            seconds: 42,
        },
        9: {
            hour: 2,
            minutes: 20,
            seconds: 55,
        },
        10: {
            hour: 2,
            minutes: 21,
            seconds: 5,
        },
        11: {
            hour: 2,
            minutes: 21,
            seconds: 19,
        },
        12: {
            hour: 2,
            minutes: 21,
            seconds: 27,
        },
        13: {
            hour: 2,
            minutes: 21,
            seconds: 41,
        },
        14: {
            hour: 2,
            minutes: 21,
            seconds: 48,
        },
        15: {
            hour: 2,
            minutes: 22,
            seconds: 3,
        },
        16: {
            hour: 2,
            minutes: 22,
            seconds: 15,
        },
        17: {
            hour: 2,
            minutes: 22,
            seconds: 36,
        },
        18: {
            hour: 2,
            minutes: 22,
            seconds: 44,
        },
        19: {
            hour: 2,
            minutes: 22,
            seconds: 57,
        },
        20: {
            hour: 2,
            minutes: 23,
            seconds: 1,
        },
        21: {
            hour: 2,
            minutes: 23,
            seconds: 15,
        },
        22: {
            hour: 2,
            minutes: 23,
            seconds: 23,
        },
        23: {
            hour: 2,
            minutes: 23,
            seconds: 32,
        },
        24: {
            hour: 2,
            minutes: 23,
            seconds: 47,
        },
        25: {
            hour: 2,
            minutes: 23,
            seconds: 54,
        },
        26: {
            hour: 2,
            minutes: 24,
            seconds: 10,
        },
        27: {
            hour: 2,
            minutes: 24,
            seconds: 21,
        },
        28: {
            hour: 2,
            minutes: 24,
            seconds: 39,
        },
        29: {
            hour: 2,
            minutes: 24,
            seconds: 57,
        },
        30: {
            hour: 2,
            minutes: 25,
            seconds: 3,
        },
        31: {
            hour: 2,
            minutes: 25,
            seconds: 7,
        },
        32: {
            hour: 2,
            minutes: 25,
            seconds: 17,
        },
        33: {
            hour: 2,
            minutes: 25,
            seconds: 35,
        },
        34: {
            hour: 2,
            minutes: 25,
            seconds: 54,
        },
        35: {
            hour: 2,
            minutes: 26,
            seconds: 2,
        },
        36: {
            hour: 2,
            minutes: 26,
            seconds: 14,
        },
        37: {
            hour: 2,
            minutes: 26,
            seconds: 32,
        },
        38: {
            hour: 2,
            minutes: 26,
            seconds: 52,
        },
        39: {
            hour: 2,
            minutes: 27,
            seconds: 24,
        },
        40: {
            hour: 2,
            minutes: 27,
            seconds: 36,
        },
    },
    19: {
        1: {
            hour: 2,
            minutes: 27,
            seconds: 59,
        },
        2: {
            hour: 2,
            minutes: 28,
            seconds: 5,
        },
        3: {
            hour: 2,
            minutes: 28,
            seconds: 13,
        },
        4: {
            hour: 2,
            minutes: 28,
            seconds: 27,
        },
        5: {
            hour: 2,
            minutes: 28,
            seconds: 48,
        },
        6: {
            hour: 2,
            minutes: 29,
            seconds: 2,
        },
        7: {
            hour: 2,
            minutes: 29,
            seconds: 38,
        },
        8: {
            hour: 2,
            minutes: 29,
            seconds: 54,
        },
        9: {
            hour: 2,
            minutes: 30,
            seconds: 9,
        },
        10: {
            hour: 2,
            minutes: 30,
            seconds: 20,
        },
        11: {
            hour: 2,
            minutes: 30,
            seconds: 36,
        },
        12: {
            hour: 2,
            minutes: 30,
            seconds: 53,
        },
        13: {
            hour: 2,
            minutes: 31,
            seconds: 38,
        },
        14: {
            hour: 2,
            minutes: 32,
            seconds: 25,
        },
        15: {
            hour: 2,
            minutes: 32,
            seconds: 37,
        },
        16: {
            hour: 2,
            minutes: 33,
            seconds: 9,
        },
        17: {
            hour: 2,
            minutes: 33,
            seconds: 56,
        },
        18: {
            hour: 2,
            minutes: 34,
            seconds: 10,
        },
        19: {
            hour: 2,
            minutes: 34,
            seconds: 30,
        },
        20: {
            hour: 2,
            minutes: 35,
            seconds: 4,
        },
        21: {
            hour: 2,
            minutes: 35,
            seconds: 22,
        },
        22: {
            hour: 2,
            minutes: 35,
            seconds: 34,
        },
        23: {
            hour: 2,
            minutes: 35,
            seconds: 45,
        },
        24: {
            hour: 2,
            minutes: 36,
            seconds: 2,
        },
        25: {
            hour: 2,
            minutes: 36,
            seconds: 19,
        },
        26: {
            hour: 2,
            minutes: 36,
            seconds: 30,
        },
        27: {
            hour: 2,
            minutes: 36,
            seconds: 43,
        },
        28: {
            hour: 2,
            minutes: 36,
            seconds: 55,
        },
        29: {
            hour: 2,
            minutes: 37,
            seconds: 19,
        },
        30: {
            hour: 2,
            minutes: 37,
            seconds: 35,
        },
        31: {
            hour: 2,
            minutes: 38,
            seconds: 6,
        },
        32: {
            hour: 2,
            minutes: 38,
            seconds: 29,
        },
        33: {
            hour: 2,
            minutes: 38,
            seconds: 39,
        },
        34: {
            hour: 2,
            minutes: 38,
            seconds: 47,
        },
        35: {
            hour: 2,
            minutes: 39,
            seconds: 8,
        },
        36: {
            hour: 2,
            minutes: 39,
            seconds: 22,
        },
        37: {
            hour: 2,
            minutes: 39,
            seconds: 32,
        },
        38: {
            hour: 2,
            minutes: 39,
            seconds: 48,
        },
        39: {
            hour: 2,
            minutes: 40,
            seconds: 8,
        },
        40: {
            hour: 2,
            minutes: 40,
            seconds: 22,
        },
        41: {
            hour: 2,
            minutes: 40,
            seconds: 33,
        },
        42: {
            hour: 2,
            minutes: 40,
            seconds: 42,
        },
    },
    20: {
        1: {
            hour: 2,
            minutes: 40,
            seconds: 50,
        },
        2: {
            hour: 2,
            minutes: 41,
            seconds: 4,
        },
        3: {
            hour: 2,
            minutes: 41,
            seconds: 20,
        },
        4: {
            hour: 2,
            minutes: 41,
            seconds: 26,
        },
        5: {
            hour: 2,
            minutes: 41,
            seconds: 34,
        },
        6: {
            hour: 2,
            minutes: 41,
            seconds: 40,
        },
        7: {
            hour: 2,
            minutes: 41,
            seconds: 53,
        },
        8: {
            hour: 2,
            minutes: 42,
            seconds: 1,
        },
        9: {
            hour: 2,
            minutes: 42,
            seconds: 10,
        },
        10: {
            hour: 2,
            minutes: 42,
            seconds: 21,
        },
        11: {
            hour: 2,
            minutes: 42,
            seconds: 25,
        },
        12: {
            hour: 2,
            minutes: 42,
            seconds: 42,
        },
        13: {
            hour: 2,
            minutes: 42,
            seconds: 55,
        },
        14: {
            hour: 2,
            minutes: 43,
            seconds: 9,
        },
        15: {
            hour: 2,
            minutes: 43,
            seconds: 16,
        },
        16: {
            hour: 2,
            minutes: 43,
            seconds: 35,
        },
        17: {
            hour: 2,
            minutes: 43,
            seconds: 43,
        },
        18: {
            hour: 2,
            minutes: 44,
            seconds: 0,
        },
        19: {
            hour: 2,
            minutes: 44,
            seconds: 13,
        },
        20: {
            hour: 2,
            minutes: 44,
            seconds: 27,
        },
        21: {
            hour: 2,
            minutes: 44,
            seconds: 44,
        },
        22: {
            hour: 2,
            minutes: 45,
            seconds: 0,
        },
        23: {
            hour: 2,
            minutes: 45,
            seconds: 16,
        },
        24: {
            hour: 2,
            minutes: 45,
            seconds: 33,
        },
        25: {
            hour: 2,
            minutes: 45,
            seconds: 53,
        },
        26: {
            hour: 2,
            minutes: 46,
            seconds: 19,
        },
        27: {
            hour: 2,
            minutes: 46,
            seconds: 36,
        },
        28: {
            hour: 2,
            minutes: 46,
            seconds: 52,
        },
        29: {
            hour: 2,
            minutes: 47,
            seconds: 0,
        },
        30: {
            hour: 2,
            minutes: 47,
            seconds: 18,
        },
        31: {
            hour: 2,
            minutes: 47,
            seconds: 24,
        },
    },
    21: {
        1: {
            hour: 2,
            minutes: 47,
            seconds: 37,
        },
        2: {
            hour: 2,
            minutes: 47,
            seconds: 45,
        },
        3: {
            hour: 2,
            minutes: 47,
            seconds: 57,
        },
        4: {
            hour: 2,
            minutes: 48,
            seconds: 16,
        },
        5: {
            hour: 2,
            minutes: 48,
            seconds: 30,
        },
        6: {
            hour: 2,
            minutes: 48,
            seconds: 36,
        },
        7: {
            hour: 2,
            minutes: 49,
            seconds: 13,
        },
        8: {
            hour: 2,
            minutes: 49,
            seconds: 32,
        },
        9: {
            hour: 2,
            minutes: 49,
            seconds: 40,
        },
        10: {
            hour: 2,
            minutes: 49,
            seconds: 50,
        },
        11: {
            hour: 2,
            minutes: 50,
            seconds: 0,
        },
        12: {
            hour: 2,
            minutes: 50,
            seconds: 11,
        },
        13: {
            hour: 2,
            minutes: 50,
            seconds: 20,
        },
        14: {
            hour: 2,
            minutes: 50,
            seconds: 27,
        },
        15: {
            hour: 2,
            minutes: 50,
            seconds: 34,
        },
        16: {
            hour: 2,
            minutes: 50,
            seconds: 57,
        },
        17: {
            hour: 2,
            minutes: 51,
            seconds: 20,
        },
        18: {
            hour: 2,
            minutes: 52,
            seconds: 13,
        },
        19: {
            hour: 2,
            minutes: 52,
            seconds: 38,
        },
        20: {
            hour: 2,
            minutes: 52,
            seconds: 49,
        },
        21: {
            hour: 2,
            minutes: 52,
            seconds: 57,
        },
        22: {
            hour: 2,
            minutes: 53,
            seconds: 7,
        },
        23: {
            hour: 2,
            minutes: 53,
            seconds: 16,
        },
        24: {
            hour: 2,
            minutes: 53,
            seconds: 30,
        },
        25: {
            hour: 2,
            minutes: 53,
            seconds: 39,
        },
    },
};
