export default {
    data() {
        return {
            isArc: false,
            isIOS: /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) || navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0,
            isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0),
            isMobileViewport: false,
            isTabletViewport: false,
            isDesktopViewport: false,
            isTouch: (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)),
        }
    },
    mounted() {
        this.checkViewport()
        window.addEventListener('resize', this.checkViewport)

        // Check if browser is Arc (no PWA support)
        setTimeout(() => {
            this.checkArc()
        }, 1000)
    },
    destroyed() {
        window.removeEventListener('resize', this.checkViewport)
    },
    methods: {
        checkArc() {
            this.isArc = getComputedStyle(document.documentElement).getPropertyValue('--arc-palette-title')
        },
        checkViewport() {
            this.isMobileViewport = window.innerWidth < 768
            this.isTabletViewport = window.innerWidth > 767 && window.innerWidth < 1200
            this.isDesktopViewport = window.innerWidth > 1199
        }
    }
}
