/*
 * This mixin is used for the DB connection to IndexedDB
 */

export default {
    data() {
        return {
            debug: this.$store.getters.getDebug,
            db: null,
            dbLoaded: false,
            intervalDB: false,
            store: 'video',
        }
    },
    created() {
        this.dbSetup()
        this.intervalDB = setInterval(this.restartDB, 250)
    },
    methods: {
        dbSetup() {
            // IndexedDB
            let indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB,
                IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction,
                dbVersion = 1.0;

            // Create/open database
            let request = indexedDB.open("videoFiles", dbVersion);
            request.onerror = (event) => {
                if(this.debug) console.log("Error creating/accessing IndexedDB database");
            };

            request.onsuccess = (event) => {
                if(this.debug) console.log("Success creating/accessing IndexedDB database");
                this.db = request.result;

                this.db.onerror = (event) => {
                    if(this.debug) console.log("Error creating/accessing IndexedDB database");
                };

                // Interim solution for Google Chrome to create an objectStore. Will be deprecated
                if(this.db.setVersion && this.db.version !== dbVersion) {
                    let setVersion = this.db.setVersion(dbVersion);
                    setVersion.onsuccess = () => {
                        this.dbCreateObjectStore(this.db);
                        this.dbLoaded = true;
                    }
                } else {
                    this.dbLoaded = true;
                }
            }

            // For future use. Currently only in latest Firefox versions
            request.onupgradeneeded = (event) => {
                this.dbCreateObjectStore(event.target.result);
            };
        },
        dbSetupStates() {
            if(this.db !== null) {
                // Open a transaction to the database
                const transaction = this.db.transaction([this.store], "readwrite");

                // Retrieve video (if exists)
                const video = transaction.objectStore(this.store).getKey('video');
                video.onsuccess = (event) => {
                    if (typeof event.target.result !== 'undefined') {
                        if (this.debug) console.log('Found file: ' + event.target.result);
                        this.$store.dispatch('setOfflineVideoAvailable', true)
                    }
                }

                // Retrieve audio (if exists)
                const audioKey = 'audio_' + this.$store.getters.getLocale.toLowerCase()
                const audio = transaction.objectStore(this.store).getKey(audioKey);
                audio.onsuccess = (event) => {
                    if (typeof event.target.result !== 'undefined') {
                        if (this.debug) console.log('Found file: ' + event.target.result);
                        this.$store.dispatch('setOfflineAudioAvailable', true)
                    }
                }

                // Retrieve subtitle (if exists)
                const subtitleKey = 'subtitle_' + this.$store.getters.getLocale.toLowerCase()
                const subtitle = transaction.objectStore(this.store).getKey(subtitleKey);
                subtitle.onsuccess = (event) => {
                    if (typeof event.target.result !== 'undefined') {
                        if (this.debug) console.log('Found file: ' + event.target.result);
                        this.$store.dispatch('setOfflineSubtitleAvailable', true)
                    }
                }
            }
        },
        dbCreateObjectStore(dataBase) {
            // Create an objectStore
            if(this.debug) console.log("Creating objectStore")
            dataBase.createObjectStore(this.store);
        },
        restartDB() {
            if(this.db === null && this.intervalDB < 60) {
                if(this.debug) console.log("Retry creating DB")
                this.dbSetup()
            } else {
                clearInterval(this.intervalDB)
            }
        }
    },
    watch: {
        dbLoaded() {
            if(this.dbLoaded) {
                this.dbSetupStates();
            }
        },
    },
}
