/*
 * This mixin is used for query related functions (updating queryString or retrieving)
 */

export default {
    methods: {
        setStateFromQueryString() {
            /*
             * Update $store state from queryString params
             * Available params: language/chapterFrom/verseFrom/chapterTo/verseTo/embed
             * Values are validated for existence
             */
            let params = {}

            if(this.getFromQueryString('language')) {
                const key = 'language'
                const val = this.getFromQueryString('language')
                params[key] = val

                if(this.$store.getters.getLanguageByLocale(val)) {
                    localStorage.setItem('locale', val)
                }
            }

            if(this.getFromQueryString('chapterFrom')) {
                const key = 'chapterFrom'
                const val = parseInt(this.getFromQueryString(key))
                params[key] = val

                if(val > 0 && this.$store.getters.getTimeCodes[val]) {
                    this.$store.dispatch('setTimeFrame', {key, val})
                }
            }

            if(this.getFromQueryString('verseFrom') && params.chapterFrom) {
                const key = 'verseFrom'
                const val = parseInt(this.getFromQueryString(key))
                params[key] = val

                if(val > 0 && this.$store.getters.getTimeCodes[params.chapterFrom][val]) {
                    this.$store.dispatch('setTimeFrame', {key, val})
                }
            }

            if(this.getFromQueryString('chapterTo')) {
                const key = 'chapterTo'
                const val = parseInt(this.getFromQueryString(key))
                params[key] = val

                // Chapter needs to exist and be greater or equal to the chapterFrom
                if(val > 0 && this.$store.getters.getTimeCodes[val] && (!params.chapterFrom || (params.chapterFrom && val >= params.chapterFrom))) {
                    this.$store.dispatch('setTimeFrame', {key, val})
                }
            }

            if(this.getFromQueryString('verseTo') && params.chapterTo) {
                const key = 'verseTo'
                const val = parseInt(this.getFromQueryString(key))
                params[key] = val

                // Verse needs to exist and be greater or equal to the verseFrom
                if(val > 0 && this.$store.getters.getTimeCodes[params.chapterTo][val] && (!params.chapterFrom || (params.chapterFrom && params.chapterTo >= params.chapterFrom)) && (!params.verseFrom || (params.verseFrom && val >= params.verseFrom))) {
                    // Prevent if chapterTo and verseTo is equal to chapterFrom and verseFrom
                    if(params.chapterFrom !== params.chapterTo || params.verseFrom !== params.verseTo) {
                        this.$store.dispatch('setTimeFrame', {key, val})
                    }
                }
            }

            if(this.getFromQueryString('embed')) {
                const key = 'embed'
                const val = this.getFromQueryString('embed')
                this.$store.dispatch('setIsEmbedded', (val === 'true' || val === '1') ?? false)
            }

            // Update currentTimeFrame as part of new skip/prev verse functionality
            if(Object.keys(params).length) {
                let chapter = params.chapterFrom ?? 0
                let verse = params.verseFrom ?? 0

                if(chapter > 0 && verse > 0) {
                    this.$store.dispatch('setCurrentTimeFrame', { 'chapter': chapter, 'verse': verse })
                }
            }

            // Set clean url state without params
            history.replaceState({}, '', window.location.pathname)
        },
        getFromQueryString(variable, fallback = null) {
            let query = window.location.search.substring(1);
            let vars = query.split('&');

            for(let i = 0; i < vars.length; i++) {
                let pair = vars[i].split('=');

                if(decodeURIComponent(pair[0].replace('[]', '')) === variable) {
                    if(pair[0].includes('[]')) {
                        fallback.push(pair[1].toLowerCase());
                    } else if(Array.isArray(fallback)) {
                        return decodeURIComponent(pair[1].toLowerCase()).split('|');
                    } else {
                        return decodeURIComponent(pair[1].toLowerCase());
                    }
                }
            }

            return fallback;
        },
    }
}
