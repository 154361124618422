// Basics
import Vue from 'vue'
import App from './App'
import './registerServiceWorker'

// Store
import store from './store'

// Plugins
import VueSvgInlinePlugin from 'vue-svg-inline-plugin'
Vue.use(VueSvgInlinePlugin)

// Mixins
import database from '@/components/mixins/database'
import connection from '@/components/mixins/connection'
import isMobile from '@/components/mixins/is-mobile'
import queryString from '@/components/mixins/querystring'
import resize from '@/components/mixins/resize'
import trans from '@/components/mixins/trans'
Vue.mixin(resize)
Vue.mixin(trans)

// Styles
import './main.css'

// Config
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    store,
    mixins: [
        database,
        connection,
        isMobile,
        queryString
    ],
    created() {
        this.setStateFromQueryString()
    }
}).$mount('#root')
