<template>
    <div id="app" class="flex items-center justify-items-center h-full w-full">
<!--        <logo-block :translations="this.$store.getters.getDefaultTranslations" />-->
        <video-block :translations="this.$store.getters.getComponentTranslations('videoBlock')" />
        <video-player-controls :translations="this.$store.getters.getComponentTranslations('videoPlayerControls')" />
        <poster-block :translations="this.$store.getters.getDefaultTranslations" />
        <languages-block :translations="this.$store.getters.getComponentTranslations('languagesBlock')" />
        <share-block :translations="this.$store.getters.getComponentTranslations('shareBlock')" />
        <about-block :translations="this.$store.getters.getComponentTranslations('aboutBlock')" />
        <chapter-block :translations="this.$store.getters.getComponentTranslations('chapterBlock')" />
        <download-block :translations="this.$store.getters.getComponentTranslations('downloadBlock')" />
        <options-block :translations="this.$store.getters.getComponentTranslations('optionsBlock')" />
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
            'about-block': () => import(/* webpackChunkName: "about-block" */ '@/components/AboutBlock'),
            'chapter-block': () => import(/* webpackChunkName: "chapter-block" */ '@/components/ChapterBlock'),
            'download-block': () => import(/* webpackChunkName: "download-block" */ '@/components/DownloadBlock'),
            'languages-block': () => import(/* webpackChunkName: "languages-block" */ '@/components/LanguagesBlock'),
            // 'logo-block': () => import(/* webpackChunkName: "logo-block" */ '@/components/LogoBlock'),
            'options-block': () => import(/* webpackChunkName: "options-block" */ '@/components/OptionsBlock'),
            'poster-block': () => import(/* webpackChunkName: "poster-block" */ '@/components/PosterBlock'),
            'share-block': () => import(/* webpackChunkName: "share-block" */ '@/components/ShareBlock'),
            'video-block': () => import(/* webpackChunkName: "video-block" */ '@/components/VideoBlock'),
            'video-player-controls': () => import(/* webpackChunkName: "video-player-controls" */ '@/components/VideoPlayerControls'),
        },
        computed: {
            checkConnection() {
                return this.$store.getters.getOnline
            },
            checkDatabaseLoaded() {
                return this.$root.dbLoaded
            },
        },
        data() {
            return {
                debug: this.$store.getters.getDebug,
            }
        },
        beforeCreate() {
            window.addEventListener("beforeinstallprompt", e => {
                e.preventDefault()
                this.$store.dispatch('setInstallPrompt', e)
            })
        },
        async created() {
            // Check incognito mode based on available estimated storage
            if(this.debug) console.log('Start incognito test')
            if("storage" in navigator && 'estimate' in navigator.storage) {
                const { usage } = await navigator.storage.estimate();
                await this.$store.dispatch('setIncognito', usage < 10000)
            } else {
                // Not detectable through this method (iOS has no navigator.storage)
            }
        },
        methods: {
            setDefaultLocale() {
                // Retrieve the locale from the localStorage (if exists), otherwise set the default
                if(!localStorage.getItem('locale')) {
                    localStorage.setItem('locale', 'en')
                }

                this.$store.dispatch('setLocale', localStorage.getItem("locale"))
            },
            updateLocale() {
                if(!this.$store.getters.getOnline) {
                    // Open a transaction to the database
                    let transaction = this.$root.db.transaction([this.$root.store], 'readwrite');

                    const keys = transaction.objectStore(this.$root.store).getAllKeys()
                    keys.onsuccess = (event) => {
                        if(event.target.result.length) {
                            let flag = false
                            event.target.result.forEach((key) => {
                                if (key.includes('audio')) {
                                    localStorage.setItem('locale', key.replace('audio_', ''))
                                    this.$store.dispatch('setLocale', key.replace('audio_', ''))
                                    flag = true
                                }
                            })

                            if(!flag) {
                                event.target.result.forEach((key) => {
                                    if (key.includes('subtitle')) {
                                        localStorage.setItem('locale', key.replace('subtitle_', ''))
                                        this.$store.dispatch('setLocale', key.replace('subtitle_', ''))
                                    }
                                })
                            }
                        } else {
                            this.setDefaultLocale()
                        }
                    }
                } else {
                    this.setDefaultLocale()
                }
            }
        },
        watch: {
            checkConnection(newVal) {
                if(!newVal) {
                    this.updateLocale()
                }
            },
            checkDatabaseLoaded(newVal) {
                if(newVal) {
                    this.updateLocale()
                }
            },
        }
    }
</script>
