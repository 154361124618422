/*
 * This mixin is used for online/offline detection
 */

export default {
    created() {
        window.addEventListener('offline', this.setOffline)
        window.addEventListener('online', this.setOnline)
    },
    destroyed() {
        window.removeEventListener('offline', this.setOffline)
        window.removeEventListener('online', this.setOnline)
    },
    methods: {
        setOffline() {
            this.$store.dispatch('setOnline', false)
        },
        setOnline() {
            this.$store.dispatch('setOnline', true)
        },
    }
}
